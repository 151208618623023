<template>
	<div class="equipment-list-container">
		<DsnPanel>
			<div slot="header" class="title clearfix">
				
				<el-form :inline="true" :model="queryParam" ref="equipmentFormRef" :rules="{}" @submit.native.prevent>
					<el-row>
						<el-row>
						<el-col :span="2.5">
							<el-form-item :label="this.$t('equipments.fields.middleCategory') + ':'" prop="categoryId" label-width="80px">
								<dsn-select
								 style="width: 150px;"
									v-model.trim="queryParam.categoryId"
									filterable
									@change="onMiddleChange"
									:placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.middleCategory')}`"
									size="medium"
									:clearable="true"
								>
									<el-option v-for="item in middleCategoryOptionList" :key="item.id" :label="item.categoryName" :value="item.id"></el-option>
								</dsn-select>
							</el-form-item>
						</el-col>
						<el-col :span="2.5" style="margin-right: 0px;">
							<el-form-item :label="this.$t('equipments.fields.subcategory') + ':'" prop="subcategoryId" label-width="80px">
								<dsn-select
									 style="width: 155px;"
									v-model.trim="queryParam.subcategoryId"
									filterable
									:placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.subcategory')}`"
									size="medium"
									:clearable="true"
								>
									<el-option v-for="item in subcategoryOptionList" :key="item.id" :label="item.categoryName" :value="item.id"></el-option>
								</dsn-select>
							</el-form-item>
						</el-col>
						<el-col :span="4.5" >
							<el-form-item :label="this.$t('equipments.fields.assetNo') + ':'" prop="assetNo" label-width="80px">
								<dsn-input :clearable="true" v-model="queryParam.assetNo" style="width: 180px;" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.assetNo')" />
							</el-form-item>
						</el-col>
						<el-col :span="4.5" style="margin-left: 0px;margin-right: 0px;">
							<el-form-item :label="this.$t('equipments.fields.name') + ':'" prop="name" label-width="80px">
								<dsn-input :clearable="true" style="width: 180px;margin-right:0px;" v-model="queryParam.name" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.name')"></dsn-input>
							</el-form-item>
						</el-col>
						<!-- 品牌 -->
						<el-col :span="2.5" style="margin-left: 0px;margin-right: 0px;">
							<el-form-item :label="this.$t('equipments.fields.brand') + ':'" prop="brand" label-width="60px">
								<dsn-input :clearable="true" v-model="queryParam.brand" style="width: 160px; margin-left: 0px;padding-left :0px" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.brand')" />
							</el-form-item>
						</el-col>
						<!-- 型号规格 -->
						<el-col :span="2.5" style="margin-left: 0px;margin-right: 0px;" >
							<el-form-item :label="this.$t('equipments.fields.modelType') + ':'" prop="modelType" label-width="80px" style="margin-right: 0px;">
								<dsn-input :clearable="true" v-model="queryParam.modelType" style="width: 160px;padding-right :0px" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.modelType')" />
							</el-form-item>
						</el-col>
						</el-row>

						
						<!-- <el-col> -->
							<!-- <el-row> -->
								<!-- <el-col :span="8">
									<el-form-item :label="this.$t('equipments.fields.name') + ':'" prop="name" label-width="80px">
										<dsn-input v-model="queryParam.name" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.name')"></dsn-input>
									</el-form-item>
								</el-col> -->
								<!-- <el-col :span="8"> -->
									<!-- <el-form-item :label="this.$t('equipments.fields.storage') + ':'" prop="storageId" label-width="80px"> -->
										<!--                    <dsn-input v-model="queryParam.storage"-->
										<!--                               :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('equipments.fields.storage')"-->
										<!--                    ></dsn-input>-->
										<!-- <el-tree-select ref="treeSelect" v-model="queryParam.storageId" :selectParams="selectParams" :treeParams="treeParams" :isSingle="true" /> -->
									<!-- </el-form-item> -->
								<!-- </el-col> -->
								<!-- <el-col :span="8">
									<el-form-item :label="this.$t('equipments.fields.sapNo') + ':'" prop="sapAssetNo" label-width="80px">
										<dsn-input v-model="queryParam.sapAssetNo" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.sapNo')" />
									</el-form-item>
								</el-col> -->
							<!-- </el-row> -->
						<!-- </el-col> -->

						
							<el-row>
								<el-col :span="4.5" >
									<!-- <el-form-item :label="this.$t('equipments.fields.acquisitionDate') + ':'" label-width="80px">
										<el-date-picker :clearable="false" v-model="twoDate" type="daterange" style="margin-right: 20px; width: 410px;"
											value-format="yyyy-MM-dd" @change="dateChange"></el-date-picker>
									</el-form-item> -->
	
									<el-form-item :label="`${this.$t('equipments.fields.acquisitionDate')}:`" prop="startDate" size="medium" label-width="80px">
										<el-date-picker v-model="queryParam.startDate" value-format="yyyy-MM-dd" style="width: 192px;" type="date" placeholder="开始日期"></el-date-picker>
										<span style="margin-left: 5px;margin-right: 5px">至</span>
										<el-date-picker  :picker-options="pickerOptions" value-format="yyyy-MM-dd" style="width: 192px;" v-model="queryParam.endDate" type="date" placeholder="结束日期"></el-date-picker>
									</el-form-item>
								</el-col>
								<el-col :span="4.5" style="margin-left: 0px;margin-right: 0px;">
									<el-form-item :label="this.$t('equipments.fields.sapNo') + ':'" prop="sapAssetNo" label-width="80px">
										<dsn-input :clearable="true" v-model="queryParam.sapAssetNo" style="width: 180px;" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.sapNo')" />
									</el-form-item>
								</el-col>

								<el-col :span="4.5" style="margin-left: 0px;margin-right: 0px;"> 
									<el-form-item :label="this.$t('equipments.fields.equipmentStatus') + ':'" prop="deviceState" label-width="80px">
										<el-select :clearable="true" v-model="queryParam.deviceState" style="width: 180px; margin-right: 0px;" placeholder="请选择">
										    <el-option
										      v-for="item1 in deviceStateoptions"
										      :key="item1.value"
										      :label="item1.label"
										      :value="item1.value">
										    </el-option>
										  </el-select>
									</el-form-item>
								</el-col>
								<el-col :span="4.5" >
								<el-form-item :label="this.$t('equipments.fields.storage') + ':'" prop="storageId" label-width="60px" >
								<!--                    <dsn-input v-model="queryParam.storage"-->
								<!--                               :placeholder="this.$t('commmsg.pleaseInput')+' '+this.$t('equipments.fields.storage')"-->
								<!--                    ></dsn-input>-->
								<el-tree-select :clearable="true" style="width: 160px;" ref="treeSelect" v-model="queryParam.storageId" :selectParams="selectParams" :treeParams="treeParams" :isSingle="true" />
								</el-form-item>
								</el-col>
								<el-col :span="4.5" style="margin-left: 0px;margin-right: 0px;" >
									<el-form-item :label="this.$t('equipments.fields.sapCheckStatus') + ':'" prop="sapState" label-width="80px"  style="margin-right: 0px;" >
										<el-select :clearable="true" v-model="queryParam.sapState" style="width: 160px;" placeholder="请选择">
										    <el-option
										      v-for="item2 in sapStateoptions"
										      :key="item2.value"
										      :label="item2.label"
										      :value="item2.value">
										    </el-option>
										  </el-select>
									</el-form-item>
								</el-col>

							</el-row>
					</el-row>
					<el-form-item>
						<dsn-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery(['equipmentFormRef'])">{{ this.$t('commbtn.query') }}</dsn-button>
						<dsn-button size="medium" type plain icon="el-icon-refresh" class="reset-button" @click="handleReset(['equipmentFormRef'])">
							{{ this.$t('commbtn.reset') }}
						</dsn-button>

						<dsn-button size="medium" type="success" icon="el-icon-folder-add" @click.native="handleAdd" v-if="isAddBtn == true">
							{{ this.$t('commbtn.add') }}
						</dsn-button>
						<dsn-button
							size="medium"
							type="primary"
							icon="el-icon-edit"
							:disabled="selectionList.length !== 1"
							@click="handleEdit"
							v-if="isEditBtn == true"
							style="margin-left:0"
						>
							{{ this.$t('commbtn.edit') }}
						</dsn-button>
						<dsn-button
							size="medium"
							type="primary"
							icon="el-icon-edit"
							:disabled="selectionList.length !== 1"
							@click="showTransferApply(true)"
							v-if="isApplyBtn == true"
							style="margin-left:0"
						>
							{{ this.$t('commbtn.apply') }}
						</dsn-button>
						<!--        <dsn-button-->
						<!--            size="medium"-->
						<!--            type="primary"-->
						<!--            icon="el-icon-edit"-->
						<!--            :disabled="selectionList.length !== 1"-->
						<!--            @click="showTransferApply(false)"-->
						<!--            style="margin-left:0"-->
						<!--        >{{ this.$t('commbtn.applyShift') }}-->
						<!--        </dsn-button>-->
						<dsn-button
							size="medium"
							type="primary"
							:disabled="selectionList.length <= 0"
							@click="printEquipmentQrCode"
							v-if="isPrintBtn == true"
							style="margin-left:0"
						>
							{{ this.$t('commbtn.printTag') }}
						</dsn-button>
						<dsn-button size="medium" type="primary" @click="exportEquipment(false)" v-if="isExportBtn == true" style="margin-left:0">
							{{ this.$t('commbtn.export') }}
						</dsn-button>
						<!-- <dsn-button size="medium" type="primary" @click="exportEquipment(true)" v-if="isExportBtn == true" style="margin-left:0">{{ this.$t('commbtn.exportFull') }}</dsn-button> -->
						<dsn-button size="medium" type="primary" :disabled="selectionList.length <= 0" style="margin-left:0" @click="SapAccept()" v-if="isAcceptBtn == true">
							设备验收
						</dsn-button>
						<dsn-button
							size="medium"
							type="danger"
							icon="el-icon-delete"
							style="margin-left:0"
							:disabled="selectionList.length < 1 || selectionList == null"
							@click="handleDeleteCheck"
							v-if="isDelBtn == true"
						>
							{{ this.$t('commbtn.delete') }}
						</dsn-button>
					</el-form-item>
				</el-form>
			</div>
			<!-- <div class="operation">
				
			</div> -->

			<!-- <dsn-table -->
			<el-table
				v-loading="pagingConfig.tableLoading"
				ref="multipleTable"
				:data="pagingConfig.tableData"
				tooltip-effect="dark"
				height="540"
				style="width: 100%;"
				stripe
				border
				@row-click="selectCheckBox"
				@selection-change="handleSelectionChange"
				:header-cell-style="{ 'font-size': '17px', 'background': '#4e81bd', 'font-family': '微软雅黑', 'font-weight': 'normal', 'color': '#ffffff','text-align':'center' }"
				:row-style="{ background: '#f9f9f9' }"
				:cell-style="{ 'font-size': '14px', 'font-family': '微软雅黑' }"
				v-adaptive="{bottomOffset: 70}"
				class="dsn-table"
			>
				<el-table-column type="selection" width="55" align="center" />
				<!--        <el-table-column type="index" label="序号" width="80" align="right"/>-->

				<el-table-column prop="assetNo" show-overflow-tooltip :label="this.$t('equipments.fields.assetNo')" width="120" align="center" fixed="left" />
				<el-table-column prop="name" show-overflow-tooltip :label="this.$t('equipments.fields.name')" min-width="180" align="left" fixed="left" />
				<el-table-column prop="brandName" show-overflow-tooltip :label="this.$t('equipments.fields.brand')" width="120" align="left" fixed="left" />
				<el-table-column prop="modelType" show-overflow-tooltip :label="this.$t('equipments.fields.modelType')" min-width="150" align="left" fixed="left" />
				<el-table-column prop="storageName" show-overflow-tooltip :label="this.$t('equipments.fields.storage')" align="left" min-width="150" />
				<el-table-column show-overflow-tooltip :label="this.$t('equipments.fields.storageKeeper')" align="left" min-width="150">
					<template slot-scope="scope">
						<span>{{ scope.row.storage == null ? null : scope.row.storage.keeperName }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="acquisitionDate" show-overflow-tooltip :label="this.$t('equipments.fields.acquisitionDate')" min-width="150" align="center" />
				<el-table-column prop="conditionId" show-overflow-tooltip :label="this.$t('equipments.fields.equipmentStatus')" min-width="100" align="center">
					<template slot-scope="scope">
						{{ conditionFormatter(scope.row.conditionId) }}
					</template>
				</el-table-column>
				<el-table-column prop="imgIds" show-overflow-tooltip :label="this.$t('equipments.fields.images')" align="center" min-width="100">
					<template slot-scope="scope">
						<el-image v-if="scope.row.imagePreview != null" style="width: 32px; height: 32px" :src="scope.row.imagePreview" :preview-src-list="scope.row.imageList">
							<div slot="error" class="image-slot"><i class="el-icon-picture-outline"></i></div>
						</el-image>
						<span v-else></span>
					</template>
				</el-table-column>
				
				<el-table-column prop="sapAcceptStatus" show-overflow-tooltip :label="this.$t('equipments.fields.sapCheckStatus')" align="center" min-width="130"></el-table-column>
				
				<!-- <el-table-column prop="sapAcceptStatus" show-overflow-tooltip :label="this.$t('equipments.fields.sapCheckStatus')" align="left" min-width="200">
				<template scope="scope">
					{{ acceptStatusFormatter(scope.row.sapAcceptStatus) }}
				</template>
				</el-table-column> {{ acceptStatusFormatter(scope.row.sapAcceptStatus) }}-->
				
				<el-table-column prop="sapAssetNo" show-overflow-tooltip :label="this.$t('equipments.fields.sapNo')" width="130" align="center" />

				<el-table-column prop="sno" show-overflow-tooltip :label="this.$t('equipments.fields.sno')" width="150" align="left" />
				<el-table-column prop="factoryName" show-overflow-tooltip :label="this.$t('equipments.fields.factory')" align="center" min-width="130" />
				
				<el-table-column prop="equipmentMainCategoryName" show-overflow-tooltip :label="this.$t('equipments.fields.mainCategory')" width="130" align="left" />
				<el-table-column prop="equipmentCategoryName" show-overflow-tooltip :label="this.$t('equipments.fields.middleCategory')" width="130" align="left" />
				<el-table-column prop="equipmentSubcategoryName" show-overflow-tooltip :label="this.$t('equipments.fields.subcategory')" width="130" align="left" />
				<!-- <el-table-column prop="runningStatusId" show-overflow-tooltip :label="this.$t('equipments.fields.runState')" align="left" min-width="150">
					<template slot-scope="scope">
						{{ scope.row.runningStatusId == null ? '' : scope.row.runningStatusId === '1' ? '开机' : '关机' }}
					</template>
				</el-table-column> -->
				<!-- </dsn-table> -->
			</el-table>

			<DsnFooter>
				<dsn-pagination
					v-if="showPage"
					background
					layout="->,total,prev,pager,next,sizes"
					:total="pagingConfig.count"
					:page-size="pagingConfig.pageSize"
					:page-sizes="[5, 10, 15, 20]"
					:current-page="pagingConfig.current"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentChange"
				></dsn-pagination>
			</DsnFooter>
		</DsnPanel>
		<el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
			<span>是否确认删除{{ selectionList.length }}条数据？</span>
			<span slot="footer" class="dialog-footer">
				<dsn-button @click="deleteDialog = false">取 消</dsn-button>
				<dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
			</span>
		</el-dialog>

		<el-dialog :title="transferFlag ? '设备调拨' : '设备转移'" :visible.sync="transferDialog" :width="'70%'" center v-el-drag-dialog>
			<DsnPanel>
				<el-form class="transfer-main-form" :inline="true" :model="transferForm" ref="testQueryForm" :rules="{}" @submit.native.prevent>
					<el-row>
						<el-col :span="12">
							<el-form-item label="调拨人:" prop="applyUserName" label-width="120px">
								<dsn-input size="medium" v-model="transferForm.applyUserName" placeholder="调拨人" :disabled="true"></dsn-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="资产编号:" prop="assetNo" label-width="120px">
								<dsn-input size="medium" v-model="transferForm.assetNo" placeholder="资产编号" :disabled="true"></dsn-input>
							</el-form-item>
						</el-col>
						<!--            <el-col :span="12">-->
						<!--              <el-form-item label="调拨单号:" prop="transferNo" label-width="120px">-->
						<!--                <dsn-input size="medium" v-model="transferForm.transferNo" placeholder="调拨单号"-->
						<!--                           :disabled="true"></dsn-input>-->
						<!--              </el-form-item>-->
						<!--            </el-col>-->
					</el-row>
					<el-row>
						<!--            <el-col :span="12">-->
						<!--              <el-form-item label="设备编号:" prop="sno" label-width="120px">-->
						<!--                <dsn-input size="medium" v-model="transferForm.sno" placeholder="设备编号" :disabled="true"></dsn-input>-->
						<!--              </el-form-item>-->
						<!--            </el-col>-->
						<el-col :span="12">
							<el-form-item label="设备名称:" prop="name" label-width="120px">
								<dsn-input size="medium" v-model="transferForm.name" placeholder="设备名称" :disabled="true"></dsn-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="型号规格:" prop="modelType" label-width="120px">
								<dsn-input size="medium" v-model="transferForm.modelType" placeholder="型号规格" :disabled="true"></dsn-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<!--            <el-col :span="12">-->
						<!--              <el-form-item label="型号规格:" prop="modelType" label-width="120px">-->
						<!--                <dsn-input size="medium" v-model="transferForm.modelType" placeholder="型号规格"-->
						<!--                           :disabled="true"></dsn-input>-->
						<!--              </el-form-item>-->
						<!--            </el-col>-->
					</el-row>
					<!--          <el-row>-->
					<!--            <el-col :span="12">-->
					<!--              <el-form-item label="主分类:" prop="mainCategoryId" label-width="120px">-->
					<!--                <dsn-input size="medium" v-model="transferForm.mainCategoryName" placeholder="主分类"-->
					<!--                           :disabled="true"></dsn-input>-->
					<!--              </el-form-item>-->
					<!--            </el-col>-->
					<!--            <el-col :span="12">-->
					<!--              <el-form-item label="中分类:" prop="middleCategoryName" label-width="120px">-->
					<!--                <dsn-input size="medium" v-model="transferForm.middleCategoryName" placeholder="中分类"-->
					<!--                           :disabled="true"></dsn-input>-->
					<!--              </el-form-item>-->
					<!--            </el-col>-->
					<!--            <el-col :span="12">-->
					<!--              <el-form-item label="小分类:" prop="subcategoryName" label-width="120px">-->
					<!--                <dsn-input size="medium" v-model="transferForm.subcategoryName" placeholder="小分类"-->
					<!--                           :disabled="true"></dsn-input>-->
					<!--              </el-form-item>-->
					<!--            </el-col>-->
					<!--          </el-row>-->
					<el-row>
						<el-col :span="12">
							<el-form-item label="调出储位:" prop="storageName" label-width="120px">
								<dsn-input size="medium" v-model="transferForm.storageName" placeholder="调出储位" :disabled="true"></dsn-input>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="调出储位负责人:" prop="storageManagerName" label-width="120px">
								<dsn-input size="medium" v-model="transferForm.storageManagerName" placeholder="" :disabled="true"></dsn-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item :label="'目标储位'" prop="storageId" label-width="120px">
								<el-tree-select
									ref="treeSelect"
									v-model="transferForm.targetStorageId"
									:selectParams="selectParams"
									:treeParams="treeParams2"
									@node-click="onTargetStorageClick"
									:isSingle="true"
								/>
							</el-form-item>
						</el-col>
						<el-col :span="12">
							<el-form-item label="目标储位负责人:" prop="targetStorageManagerName" label-width="120px">
								<dsn-input size="medium" v-model="transferForm.targetStorageManagerName" placeholder="" :disabled="true"></dsn-input>
							</el-form-item>
						</el-col>
					</el-row>
					<!--          <el-row>-->
					<!--            <el-col :span="12">-->
					<!--              <el-form-item label="备注:" prop="remark" label-width="120px">-->
					<!--                <dsn-input type="textarea" size="medium" v-model="transferForm.remark" placeholder="备注"-->
					<!--                ></dsn-input>-->
					<!--              </el-form-item>-->
					<!--            </el-col>-->
					<!--          </el-row>-->
				</el-form>
			</DsnPanel>
			<div class="transfer-dialog-footer">
				<dsn-button-save size="medium" @click.native="onApplySubmit">{{ this.$t('commbtn.submit') }}</dsn-button-save>
				<dsn-button-save size="medium" plain icon="el-icon-close" @click="onApplyClose">{{ this.$t('commbtn.cancel') }}</dsn-button-save>
			</div>
		</el-dialog>
	</div>
</template>
<script>
import { mapMutations } from 'vuex';
import DsnFooter from '@/views/layout/dsn-footer';
import { deleteOneEquipmentById, exportEquipmentExcel, getEquipmentList, SapAccept } from '@/api/efms/equipments/equipments.api';
import DsnPanel from '@/components/global/PageLayout/DsnPanel';
import { getStorageList } from '@/api/efms/equipments/equipment.storage.api';
import arrayToTree from 'array-to-tree';
import { printBarcode } from '@/api/efms/equipments/equipment.tag.api';
import { applyEquipmentTransfer } from '@/api/efms/equipments/equipment.transfer.api';
import { applyEquipmentShift } from '@/api/efms/equipments/equipment.shift.api';
import { getUserList } from '@/api/efms/users/users.api';
import { getEquipmentCategoryList } from '@/api/efms/equipments/equipment.category.api';

export default {
	name: 'EquipmentList',
	components: { DsnPanel, DsnFooter },
	inject: ['defaltDialogWidth'],
	data() {
		return {
			listData: [],
			transferDialog: false,
			twoDate:[],
			queryParam: {
				SubcategoryName: null,
				CategoryName: null,
				categoryId: null,
				subcategoryId: null,
				MainCategoryName: null,
				name: null,
				assetNo: null,
				sno: null,
				storageId:null,
				startDate:null,
				endDate:null,
				deviceState:null,
				sapState:null,
				sapAssetNo:null,
				brand:null,
				modelType:null,
			},
			deviceStateoptions:[
				{
					label:'使用中',
					value:1,
				},
				{
					label:'闲置',
					value:2,
				},
				{
					label:'淘汰',
					value:3,
				},
				{
					label:'报废',
					value:4,
				}
			],
			
			sapStateoptions:[
				{
					label:'未验收',
					value:0,
				},
				{
					label:'已验收',
					value:1,
				}
			],

			showPage: true,
			pagingConfig: {
				current: 1,
				pageSize: 10,
				count: 0,
				tableLoading: false,
				total: 0
			},
			deleteDialog: false,
			selectionList: [],
			printDataList: [
				{
					companyName: '公司名称'
				}
			],
			printForm: {
				name: ''
			},
			codeImageList: [],
			storageFullList: [],
			transferForm: {
				applyUserId: null,
				applyUserName: null,
				modelType: null,
				mainCategoryId: null,
				middleCategoryId: null,
				subcategoryId: null,
				equipmentId: null,
				applicant: null,
				toApproveBy: null,
				equipmentName: null,
				storageId: null,
				targetStorageId: null
			},
			defaultTransferForm: {},
			userList: [],
			transferFlag: true,
			middleCategoryOptionList: [],
			subcategoryOptionList: [],
			fullStorageTree: [],
			selectParams: {
				multiple: false,
				clearable: true,
				placeholder: '请选择'
			},
			treeParams: {
			  clickParent: true,
			  filterable: false,
			  'check-strictly': true,
			  'default-expand-all': true,
			  'expand-on-click-node': false,
			  data: [],
			  props: {
			    children: 'children',
			    label: 'position',
			    // disabled: 'disabled',
			    value: 'id'
			  }
			},
			treeParams2: {
			  clickParent: true,
			  filterable: false,
			  'check-strictly': true,
			  'default-expand-all': true,
			  'expand-on-click-node': false,
			  data: [],
			  props: {
			    children: 'children',
			    label: 'position',
			    disabled: 'disabled',
			    value: 'id'
			  }
			},
			pickerOptions: {
			     //      disabledDate(time) {
						  // return time.getTime() < new Date(this.queryParam.startDate);
			     //        // return time.getTime() < Date.now(this.queryParam.startDate);
			     //      },
			        },

			isAddBtn: false,
			isEditBtn: false,
			isApplyBtn: false,
			isPrintBtn: false,
			isExportBtn: false,
			isAcceptBtn: false,
			isDelBtn: false
		};
	},
	computed: {},
	created() {
		this.initFullCategoryList();
		// this.initFullStorageList();
		this.getData();

		var permissionsList = JSON.parse(localStorage.getItem('USER_PERMISSIONS'));

		if (permissionsList.indexOf('api:equipments:viewExport') != -1) {
			this.isExportBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:addPrint') != -1) {
			this.isAddBtn = true;
			this.isPrintBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:transfer') != -1) {
			this.isApplyBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:sapAccept') != -1) {
			this.isAcceptBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:edit') != -1) {
			this.isEditBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:del') != -1) {
			this.isDelBtn = true;
		}
	},
	activated() {
		this.initFullCategoryList();
		// this.initFullStorageList();
		this.getData();
	},
	methods: {
		// dateChange(e) {
		// 	this.queryParam.startDate = e[0];
		// 	this.queryParam.endDate = e[1];
		// },
		SapAccept() {
			console.log(this.selectionList);
			var list = [];
			this.selectionList.forEach(obj => {
				list.push(obj.id);
			});
			let params = { list: list };
			SapAccept(params).then(projectRes => {
				console.log(projectRes);
				if (projectRes.data.resultCode == 0) {
					this.notification().success({
						body: projectRes.data.message
					});
					this.getData();
				} else {
					this.notification().error({
						body: projectRes.data.errMsg
					});
				}
			});
		},

		...mapMutations(['EQUIPMENTLIST']),
		handleQuery(formNames) {			
			this.$refs[formNames].validate(valid => {
				if (valid) {
					this.pagingConfig.current = 1;
					this.initMainList(false);
				} else {
					return false;
				}
			});
		},
		// resetForm(formName) {
		//         this.$refs[formName].resetFields();
		//       }
		handleReset(formName) {
			console.log(formName);
			// formName.forEach(element => {
			// 	this.$refs[element].resetFields();
			// });
			
			this.queryParam.SubcategoryName = null;
			this.queryParam.CategoryName = null;
			this.queryParam.categoryId = null;
			this.queryParam.subcategoryId = null;
			this.queryParam.MainCategoryName = null;
			this.queryParam.name = null;
			this.queryParam.assetNo = null;
			this.queryParam.sno = null;
			this.queryParam.startDate = null;
			this.queryParam.deviceState = null;
			this.queryParam.sapState = null;
			this.queryParam.endDate = null;
			this.queryParam.storageId = null;
			this.queryParam.sapAssetNo = null;
			this.queryParam.brand = null;
			this.queryParam.modelType = null;
			
			this.$forceUpdate();
			this.handleQuery(formName[0]);
			
			// this.$refs[formName].resetFields();
			// // this.twoDate=[];
			// // this.queryParam.startDate=null;
			// // this.queryParam.endDate=null;
			// // this.$forceUpdate();
			// this.handleQuery(formName);
		},
		handlePageSizeChange(pageSize) {
			this.pagingConfig.pageSize = pageSize;
			this.pagingConfig.current = 1;
			this.initMainList();
		},
		handleCurrentChange(currentChange) {
			this.pagingConfig.current = currentChange;
			console.info(this.pagingConfig.current);
			this.initMainList(true);
		},
		handleSelectionChange(val) {
			this.selectionList = val;
		},
		selectCheckBox(row) {
			this.$refs.multipleTable.toggleRowSelection(row);
		},
		handleEdit() {
			const tempArr = JSON.parse(JSON.stringify(this.selectionList));
			this.EQUIPMENTLIST(tempArr);
			localStorage.setItem('equipmentList', JSON.stringify(this.selectionList));
			this.$router.push({
				name: 'equipmentEdit',
				query: { operateType: 'edit' }
			});
		},
		handleAdd() {
			// const tempArr = JSON.parse(JSON.stringify(this.selectionList));
			this.EQUIPMENTLIST([]);
			// localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
			this.$router.push({
				name: 'equipmentAdd',
				query: { operateType: 'add' }
			});
		},
		handleDelete() {
			const { selectionList } = this;
			console.info(selectionList);
			let that = this;
			if (selectionList.length > 0) {
				selectionList.forEach(obj=>{
					deleteOneEquipmentById(obj.id)
						.then(res => {
							console.info(res);
							const { status, data } = res;
							if (status === 200 && data != null && data.resultCode === 0) {
								that.initMainList(true);
								this.$message.success('删除成功');
							} else {
								this.$message.error(`删除失败${data.errMsg}`);
							}
						})
						.catch(err => {
							this.$message.error('删除失败');
						});
				})
				
			} else {
				this.$message.error('请选择一条要删除的记录');
			}
		},
		handleDeleteCheck() {
			this.messageBox()
				.then(() => {
					this.handleDelete();
				})
				.catch(() => {
					this.notification().warn({
						body: '已经取消删除'
					});
				});
		},
		getData() {
			this.initUserList();
			this.initMainList(true);
			this.initStorageList();
			this.initStorageList2();
		},

		initUserList() {
			getUserList({
				page: 0,
				pageSize: 0
			}).then(response => {
				const { data, status } = response;
				if (status === 200) {
					this.userList = [...data.data];
				} else {
					this.notification().warn({
						body: data.errMsg
					});
				}
			});
		},
		initMainList(showPage) {
			if (!showPage) {
				this.pagingConfig.current = 1;
			}
			const data = {
				...this.queryParam,
				page: this.pagingConfig.current,
				pageSize: this.pagingConfig.pageSize
			};
			this.showPage = showPage;
			this.pagingConfig.tableLoading = true;
			getEquipmentList(data).then(response => {
				const { status, errMsg, data } = response;
				if (status === 200) {
					if (data != null && data.data.length > 0) {
						this.showPage = true;
						this.pagingConfig.count = data.totalCount;
						let dataList = data.data;
						if (dataList != null && dataList.length > 0) {
							for (let i = 0; i < dataList.length; i++) {
								let item = dataList[i];
								if (item.imgIds != null) {
									let imageUrl = this.convertEquipmentImage(item.imgIds);
									if (imageUrl != null && imageUrl.length > 0) {
										Object.assign(item, {
											imagePreview: imageUrl[0],
											imageList: imageUrl
										});
									}
								}
							}
							this.pagingConfig.tableData = [...dataList];
						} else {
							this.pagingConfig.tableData = [];
						}
						this.$forceUpdate();
					} else {
						this.showPage = false;
						this.pagingConfig.count = 0;
						this.pagingConfig.tableData = [];
						this.$forceUpdate();
					}
				} else {
					this.notification().warn({
						body: errMsg
					});
				}
				this.pagingConfig.tableLoading = false;
			});
		},
		conditionFormatter(value) {
			if (value != null) {
				// 1. 使用中
				// 2. 闲置
				// 3.淘汰
				// 4. 报废
				switch (value) {
					case '1':
						return '使用中';
					case '2':
						return '闲置';
					case '3':
						return '淘汰';
					case '4':
						return '报废';
					default:
						return '';
				}
			} else {
				return '';
			}
		},
		printEquipmentQrCode() {
			let selectedPrintList = this.selectionList;
			if (selectedPrintList != null && selectedPrintList.length > 0) {
				let ids = selectedPrintList.map(v => {
					return v.id;
				});
				printBarcode(ids).then(res => {
					const { status } = res;
					if (status === 200) {
						this.$message.success('提交成功');
					}
				});
			}
		},
		initStorageList() {
			//获取主分类下拉框
			let postData = {
				page: 0,
				pageSize: 0
			};
			getStorageList(postData).then(res => {
				const { status, data } = res;
				if (status != null && data != null && data.data.length > 0) {
					this.storageFullList = [...data.data];
					let tempList = data.data.map(v => {
						return {
							position: v.equipmentStorageName,
							id: v.id,
							costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
							parentId: v.parentId == null || v.parentId === '' ? null : v.parentId,
							keeperId: v.keeperId,
							keeperName: v.keeperName,
							children: null
						};
					});
					let resultList = arrayToTree(tempList, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					if (resultList.length > 0) {
						resultList.forEach(item => {
							item.disabled = false;
							let nodeChildren = item.children;
							if (nodeChildren != null && nodeChildren.length > 0) {
								nodeChildren.forEach(secItem => {
									if (secItem != null && secItem.children != null && secItem.children.length > 0) {
										secItem.disabled = false;
										secItem.children.forEach(third => {
											Object.assign(third, {
												disabled:false
											});
										});
									}
								});
							}
						});
					}
					this.treeParams.data = [...resultList];
					// this.treeParams2.data = [...resultList];
					this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
					this.storageNodeList = [...resultList];
					this.$forceUpdate();
					// console.log('this.treeParams.data --------------------',this.treeParams.data);
				} else {
					this.storageNodeList = [];
					this.treeParams.data = [];
					// this.treeParams2.data = [];
					this.$forceUpdate();
					this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
				}
			});
		},
		initStorageList2() {
			//获取主分类下拉框
			let postData = {
				page: 0,
				pageSize: 0
			};
			getStorageList(postData).then(res => {
				const { status, data } = res;
				if (status != null && data != null && data.data.length > 0) {
					this.storageFullList = [...data.data];
					let tempList = data.data.map(v => {
						return {
							position: v.equipmentStorageName,
							id: v.id,
							costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
							parentId: v.parentId == null || v.parentId === '' ? null : v.parentId,
							keeperId: v.keeperId,
							keeperName: v.keeperName,
							children: null
						};
					});
					let resultList = arrayToTree(tempList, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					if (resultList.length > 0) {
						resultList.forEach(item => {
							item.disabled = true;
							let nodeChildren = item.children;
							if (nodeChildren != null && nodeChildren.length > 0) {
								nodeChildren.forEach(secItem => {
									if (secItem != null && secItem.children != null && secItem.children.length > 0) {
										secItem.disabled = true;
										secItem.children.forEach(third => {
											Object.assign(third, {
												disabled:false
											});
										});
									}
								});
							}
						});
					}
					// this.treeParams.data = [...resultList];
					this.treeParams2.data = [...resultList];
					// this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
					this.storageNodeList = [...resultList];
					this.$forceUpdate();
					// console.log('this.treeParams.data --------------------',this.treeParams.data);
				} else {
					this.storageNodeList = [];
					// this.treeParams.data = [];
					this.treeParams2.data = [];
					this.$forceUpdate();
					// this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
				}
			});
		},
		showTransferApply(flag) {
			this.transferFlag = flag;
			console.info(flag);
			if (this.selectionList != null && this.selectionList.length === 1) {
				let profile = localStorage.getItem('userProfile');
				let userProfile = null;
				if (profile != null && profile.length > 0) {
					userProfile = JSON.parse(profile);
				}
				this.transferDialog = true;
				this.transferForm = this.selectionList[0];
				Object.assign(this.transferForm, {
					applyUserId: userProfile == null ? null : userProfile.id,
					applyUserName: userProfile == null ? null : userProfile.realname,
					mainCategoryId: this.transferForm.equipmentMainCategoryId,
					mainCategoryName: this.transferForm.equipmentMainCategoryName,
					middleCategoryId: this.transferForm.equipmentCategoryId,
					middleCategoryName: this.transferForm.equipmentCategoryName,
					subcategoryName: this.transferForm.equipmentSubcategoryName,
					subcategoryId: this.transferForm.equipmentSubcategoryId
				});
				const { storageId } = this.transferForm;
				if (storageId != null && storageId !== '') {
					let targetStorages = this.storageFullList.filter(s => s['id'] === storageId);
					if (targetStorages != null && targetStorages.length === 1) {
						let keepers = targetStorages[0].keeperId;
						if (keepers != null && keepers.length > 0) {
							if (keepers.indexOf(',') > -1) {
								let users = keepers.split(',');
								let nameArrays = [];
								Object.assign(this.transferForm, {
									storageManagerId: keepers
								});
								if (users != null && users.length > 0) {
									users.forEach(v => {
										let targetUser = this.userList.filter(s => s.id === v);
										if (targetUser != null && targetUser.length > 0) {
											nameArrays.push(targetUser[0].realname);
										}
									});
								}
								Object.assign(this.transferForm, {
									storageManagerName: nameArrays.join(','),
									storageManagerId: keepers
								});
							} else {
								let targetUser = this.userList.filter(s => s.id === keepers);
								if (targetUser != null && targetUser.length > 0) {
									Object.assign(this.transferForm, {
										storageManagerId: targetUser[0].id,
										storageManagerName: targetUser[0].realname
									});
								}
							}
						}
					}
				}
			}
			console.info(this.transferForm);
		},
		onApplyClose() {
			this.transferDialog = false;
		},
		onApplySubmit() {
			let formData = JSON.parse(JSON.stringify(this.transferForm));
			console.info(formData);
			if (this.transferForm.storageId === null || this.transferForm.storageId === undefined || this.transferForm.storageId === '') {
				this.$message.error('请设置设备储位');
				return;
			}
			if (this.transferForm.storageManagerId === null || this.transferForm.storageManagerId === undefined || this.transferForm.storageManagerId === '') {
				this.$message.error('请设置储位保管人');
				return;
			}
			if (this.transferForm.targetStorageId === null || this.transferForm.targetStorageId === undefined || this.transferForm.targetStorageId === '') {
				this.$message.error('请设置目标储位');
				return;
			}
			if (this.transferForm.targetStorageManagerId === null || this.transferForm.targetStorageManagerId === undefined || this.transferForm.targetStorageManagerId === '') {
				this.$message.error('请设置目标储位保管人');
				return;
			}
			if (this.transferForm.applyUserId === null) {
				this.$message.error('调拨申请人id不能为空');
				return;
			}
			if (this.transferForm.targetStorageId != null && this.transferForm.storageId != null && this.transferForm.targetStorageId === this.transferForm.storageId) {
				this.$message.error('储位相同无法调拨或转移');
				return;
			}
			let postDat = {
				// transferNo: new Date().getTime(),
				mainCategoryId: formData['mainCategoryId'] == null || formData['mainCategoryId'] === undefined ? null : formData['mainCategoryId'],
				mainCategoryName: formData['mainCategoryName'] == null || formData['mainCategoryName'] === undefined ? null : formData['mainCategoryName'],
				middleCategoryId: formData['middleCategoryId'],
				middleCategoryName: formData['middleCategoryName'],
				subcategoryId: formData['subcategoryId'],
				subcategoryName: formData['subcategoryName'],
				applyUserId: formData['applyUserId'],
				equipmentId: formData['id'],
				equipmentName: formData['name'],
				equipmentSno: formData['sno'],
				storageId: formData['storageId'],
				// storageName: formData['storageName'],
				storageManagerId: formData['storageManagerId'],
				// storageManager: formData['storageManagerName'],
				targetStorageId: formData['targetStorageId'],
				// targetStorageName: formData['targetStorageName'],
				targetStorageManagerId: formData['targetStorageManagerId'],
				// targetStorageManager: formData['targetStorageManagerName'],
				// applyDate: null,
				// confirmStatus: 'WAIT_TO_CONFIRM',
				remark: formData['remark']
			};
			if (this.transferFlag) {
				applyEquipmentTransfer(postDat)
					.then(res => {
						const { status, data } = res;
						if (status === 200 && data.resultCode === 0) {
							this.transferDialog = false;
							this.$message.success('提交成功');
						} else {
							this.$message.error(data.errMsg);
						}
					})
					.catch(err => {
						this.$message.error(err.errMsg);
					});
			} else {
				applyEquipmentShift(postDat)
					.then(res => {
						const { status, data } = res;
						if (status === 200 && data.resultCode === 0) {
							this.transferDialog = false;
							this.$message.success('提交转移成功');
						} else {
							this.$message.error(data.errMsg);
						}
					})
					.catch(err => {
						this.$message.error(err.errMsg);
					});
			}
		},
		onTargetStorageClick(event) {
			const { keeperId, keeperName, position } = event;
			// if (keeperId != null) {
			// 	let nameArray = [];
			// 	if (keeperId.indexOf(',')) {
			// 		let users = keeperId.split(',');
			// 		if (users != null && users.length > 0) {
			// 			users.forEach(v => {
			// 				let targets = this.userList.filter(s => s.id === v);
			// 				if (targets != null && targets.length > 0) {
			// 					nameArray.push(targets[0].realname);
			// 				}
			// 			});
			// 		}
			// 	} else {
			// 		let targets = this.userList.filter(s => s.id === keeperId);
			// 		if (targets != null && targets.length > 0) {
			// 			nameArray.push(targets[0].realname);
			// 		}
			// 	}
			// 	console.log('nameArray',nameArray);
			// 	Object.assign(this.transferForm, {
			// 		targetStorageManagerId: keeperId,
			// 		targetStorageManagerName: nameArray == null ? null : nameArray.join(','),
			// 		targetStorageName: position
			// 	});
			// }
			if (keeperId != null) {
				let nameArray = [];
				nameArray.push(keeperName);
				console.log('nameArray',nameArray);
				Object.assign(this.transferForm, {
					targetStorageManagerId: keeperId,
					targetStorageManagerName: nameArray == null ? null : nameArray.join(','),
					targetStorageName: position
				});
			}
		},
		acceptStatusFormatter(data) {
			// console.log('++++++++++++++++++++++++++++',data);
			return data == null ? '未验收' : data === '' ? '未验收' : '已验收';
		},

		initFullCategoryList() {
			getEquipmentCategoryList({ page: 0, pageSize: 0 }).then(res => {
				console.info(res);
				const { status, data } = res;
				if (status === 200 && data.data != null && data.data.length > 0) {
					let fullTree = arrayToTree(data.data, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					if (fullTree != null && fullTree.length > 0) {
						console.info(fullTree);
						if (fullTree[0].children != null) {
							this.middleCategoryOptionList = [...fullTree[0].children];
						}
					}
				}
			});
		},
		initFullStorageList() {
			let postData = {
				page: 0,
				pageSize: 0
			};
			getStorageList(postData).then(res => {
				const { status, data } = res;
				if (status != null && data != null && data.data.length > 0) {
					// this.storageFullList = [...data.data]
					let tempList = data.data.map(v => {
						return {
							position: v.equipmentStorageName,
							id: v.id,
							costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
							parentId: v.parentId == null || v.parentId === '' ? null : v.parentId,
							children: null
						};
					});
					let resultList = arrayToTree(tempList, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					if (resultList.length > 0) {
						resultList.forEach(item => {
							item.disabled = true;
							let nodeChildren = item.children;
							if (nodeChildren != null && nodeChildren.length > 0) {
								nodeChildren.forEach(secItem => {
									if (secItem != null && secItem.children != null && secItem.children.length > 0) {
										secItem.disabled = true;
										secItem.children.forEach(third => {
											Object.assign(third, {
												disabled:false
											});
										});
									}
								});
							}
						});
					}
					this.treeParams.data = [...resultList];
					this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
					// this.storageNodeList = [...resultList]
				} else {
					// this.storageNodeList = []
					this.treeParams.data = [];
					this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
				}
			});
		},
		onMiddleChange(value) {
			console.info(value);
			let targetItems = this.middleCategoryOptionList.filter(s => s.id === value);
			if (targetItems != null && targetItems.length > 0) {
				let subList = targetItems[0].children == null ? [] : JSON.parse(JSON.stringify(targetItems[0].children));
				this.subcategoryOptionList = [...subList];
			}
		},
		exportEquipment(exportFull) {
			let params = {
				...this.queryParam
			};
			if (exportFull) {
				params = {
					page: 0,
					pageSize: 0
				};
			}
			exportEquipmentExcel(params).then(res => {
				const { status, data } = res;
				// let blob = new Blob(data)
				let url = window.URL.createObjectURL(data);
				let a = document.createElement('a');
				a.href = url;
				a.download = '设备信息.xlsx';
				a.click();
				window.URL.revokeObjectURL(url);
			});
		},
		convertEquipmentImage(imgIds) {
			let result = [];
			if (imgIds != null && imgIds.length > 0) {
				let temp = imgIds.map(x => {
					return `https://cpm.deartsz.com/api/equipments/download/${x}`;
				});
				result = [...temp];
			}
			return result;
		}
	}
};
</script>
<style lang="scss" scoped>
.equipment-list-container {
	.operation {
		width: 100%;
		height: 48px;

		.dsn-button {
			margin: 0 8px 0 0;
		}
	}
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}

.print-container {
	display: none;
}

.print-form {
	border-radius: 15px;
	height: 200px;
	width: 300px;
	border: 1px solid #000;

	.title {
		font-weight: bold;
		text-align: center;
	}

	.form_field {
		margin-left: 10px;
	}
}

.transfer-main-form {
	.el-form-item {
		margin: 0 24px 16px 0;

		.el-select {
			width: 320px;
		}

		.el-input {
			width: 320px;
		}
	}
}

.transfer-dialog-footer {
	text-align: center;
}
::v-deep .el-table__body-wrapper{
       z-index: 2
}
</style>
