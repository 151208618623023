<template>
  <div class="storage-add-container">
    <div class="operate">
      <dsn-button
          size="medium"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t('commbtn.back') }}
      </dsn-button>
      <dsn-button-save size="medium"
                       @click.native="checkAdd(['storageFormRef'])">{{ this.$t('commbtn.save') }}
      </dsn-button-save
      >
      <dsn-button
          size="medium"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['storageFormRef'])"
      >{{ this.$t('commbtn.reset') }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="storageForm"
          ref="storageFormRef"
          :inline="true"
          :rules="storageFormRules"
          class="equipment-main-form"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item :label="this.$t('storage.fields.parentNode')" prop="parentId"
                          :label-width="labelWidth">
              <!--              <storage-tree-select v-model="storageForm.parentId" :data="storageNodeList"/>-->
              <el-tree-select ref="treeSelect" v-model="storageForm.parentId" :selectParams="selectParams"
                              :treeParams="treeParams"
                              @node-click="onSelectChange"
                              :isSingle="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item :label="this.$t('storage.fields.storageName')" prop="storageName"
                        :label-width="labelWidth">
            <dsn-input
                v-model.trim="storageForm.storageName"
                placeholder="储位名称"
            ></dsn-input>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item :label="this.$t('storage.fields.keeper')" prop="keeperId"
                        :label-width="labelWidth">
            <el-select filterable multiple v-model="storageForm.keeperId" placeholder="请选择保管人">
              <el-option
                  v-for="item in userOptionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item :label="this.$t('storage.fields.productGroupCode')" prop="productionGroupCode"
                          :label-width="labelWidth">
              <dsn-input
                  v-model.trim="storageForm.productionGroupCode"
                  placeholder="生产组代号"
              ></dsn-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item :label="this.$t('storage.fields.productionAreaFlag')" prop="productionAreaFlag"
                          :label-width="labelWidth">
              <!--              <dsn-input-->
              <!--                  v-model.trim="storageForm.productionAreaFlag"-->
              <!--                  placeholder="生产组区域标识"-->
              <!--              ></dsn-input>-->
              <el-checkbox label="" :true-label="1" :false-label="0"
                           v-model="storageForm.productionAreaFlag" size="medium"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item :label="this.$t('storage.fields.costCenterNo')" prop="costCenterNo"
                          :label-width="labelWidth">
              <!-- <dsn-input
                  v-model.trim="storageForm.costCenterNo"
                  placeholder="SAP成本中心"
              ></dsn-input> -->
			  <el-select filterable v-model="storageForm.costCenterNo" placeholder="请选择成本中心">
			    <el-option
			        v-for="item in costList"
			        :key="item.id"
			        :label="item.Description"
			        :value="item.CostCenterName">
			    </el-option>
			  </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>

</template>
<script>

import StorageTreeSelect
  from "@/views/mes/basic_settings/equipments_position_ref_settings/components/storage-tree-select";
import {getUserList} from "@/api/efms/users/users.api";
import {addStorage, getStorageList,CostCenter} from "@/api/efms/equipments/equipment.storage.api";
import arrayToTree from "array-to-tree";

export default {
  name: 'equipmentAdd',
  components: {StorageTreeSelect},
  data() {
    const valiIP = (rule, value, callback) => {
      if (value === "") {
        callback("IP地址不能为空");
      }
      let reg = /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
      if (!reg.test(value)) {
        callback("IP地址格式错误");
      }
      callback();
    }
    return {
      isAddMainCategory: true,//初始化添加主类型的
      isEditStation: false, //是否可编辑站位
      storageForm: {
        storageName: null,
        productionGroupCode: null,
        productionAreaFlag: 0,
        costCenterNo: null,
        parentId: null,
        keeperId: null,
      },
      labelWidth: "120px",
      storageNodeList: [],
      userOptionList: [],
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: '请选择'
      },
      treeParams: {
        clickParent: true,
        filterable: false,
        'check-strictly': true,
        'default-expand-all': true,
        'expand-on-click-node': false,
        data: [],
        props: {
          children: 'children',
          label: 'position',
          disabled: 'disabled',
          value: 'id'
        }
      },
	  costList:[],
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
    this.initSelectData();
	this.getCostCenter();
  },
  computed: {
    storageFormRules: function () {
      let that = this
      return {
        scheduleNo: [{required: true, message: "请输入盘点单号", trigger: "change"}],
        storageName: [{required: true, message: "请输入储位名称", trigger: "change"}],
        costCenterNo: [{required: false, message: "请输入成本中心", trigger: "change"}],
        keeperId: [{required: true, message: "请选储位保管人", trigger: "change"}],
      }
    }
  },
  methods: {
	  getCostCenter(){
	  		  let params = '';
	  		  CostCenter(params).then(costRes => {
	  		  	console.log(costRes);
	  		  	if (costRes.data.resultCode == 0) {
	  				this.costList = costRes.data.data
	  		  		// this.notification().success({
	  		  		// 	body: costRes.data.message
	  		  		// });
	  		  	} else {
	  		  		// this.notification().error({
	  		  		// 	body: costRes.data.errMsg
	  		  		// });
	  		  	}
	  		  });
	  },
    handleBack() {
      this.$router.pushAndClear(
          {name: "equipmentStockRefSettings"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      let postData = {};
      postData['parentId'] = this.storageForm.parentId;
      postData['equipmentStorageName'] = this.storageForm.storageName;
      postData['costCenterNo'] = this.storageForm.costCenterNo;
      postData['costCenterId'] = this.storageForm.costCenterNo;
      postData['productionGroupCode'] = this.storageForm.productionGroupCode;
      postData['productionAreaFlag'] = this.storageForm.productionAreaFlag;
      // postData['keeperId'] = this.storageForm.keeperId;
      if (this.storageForm.keeperId != null && this.storageForm.keeperId.length > 0) {
        let keeperIds = this.storageForm.keeperId.join(",");
        postData['keeperId'] = keeperIds;
      } else {
        postData['keeperId'] = null;
      }
      addStorage(postData).then(result => {
        const {status, errMsg, data} = result;
        if (status === 200) {
          this.notification().success({
            body: "保存成功",
          });
          this.handleBack();
        } else {
          this.notification().error({
            body: errMsg,
          });
        }
      })
    },
    handleReset(formName) {
      if (this.operateType === "add") {
        formName.forEach((element) => {
          this.$refs[element].resetFields();
        });
      }
    },
    initSelectData() {
      this.initCategoryList();
    },
    initCategoryList() {
      this.getStorageMainList();
      this.getUserList();
    },
    getUserList() {
      let postData = {
        page: 1,
        pageSize: 9999
      }
      getUserList(postData).then(res => {
        const {status, data} = res;
        if (status === 200 && data != null) {
          let resultList = data.data
          if (resultList != null && resultList.length) {
            this.userOptionList = resultList.map((v) => {
              return {
                id: v.id,
                label: v.realname + `(${v.username})`,
                value: v.id
              }
            })
          }
        }
      })
    },
    getStorageMainList() {//获取主分类下拉框
      let postData = {
        page: 0,
        pageSize: 0
      }
      getStorageList(postData).then(res => {
        const {status, data} = res
        if (status != null && data != null && data.data.length > 0) {
          this.storageFullList = [...data.data]
          let tempList = data.data.map(v => {
            return {
              position: v.equipmentStorageName,
              id: v.id,
              costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
              parentId: v.parentId == null || v.parentId === "" ? null : v.parentId,
              children: null,
            }
          })
          let resultList = arrayToTree(tempList, {
            parentProperty: 'parentId',
            childrenProperty: 'children'
          })
          if (resultList.length > 0) {
            resultList.forEach(item => {
              let nodeChildren = item.children;
              if (nodeChildren != null && nodeChildren.length > 0) {
                nodeChildren.forEach(secItem => {
                  if (secItem != null && secItem.children != null && secItem.children.length > 0) {
                    secItem.children.forEach(third => {
                      Object.assign(third, {
                        disabled: true
                      })
                    })
                  }
                })
              }
            })
          }
          console.info(resultList);
          this.treeParams.data = [...resultList]
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
          this.storageNodeList = [...resultList]
        } else {
          this.storageNodeList = []
          this.treeParams.data = []
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
        }
      })
    },
    onStorageParentChange(value) {
      console.info(value)
    },
    onSelectChange(node) {
      let nodeLevel = -1;
      if (node != null) {
        if (node.parentId === null || node.parentId === "") {
          nodeLevel = 0;
        } else {
          let parentNodes = this.storageFullList.filter(s => s.id === node.parentId);
          if (parentNodes == null) {
            nodeLevel = -1
          } else {
            let pNode = parentNodes[0];
            if (pNode.parentId == null || pNode.parentId === "") {
              nodeLevel = 1;
            } else {
              nodeLevel = 2;
            }
          }
        }
      }
      if (nodeLevel > 1) {
        this.$message.warning("当前储位不能作为上级储位 当前储位层级为3")
        this.storageForm.parentId = null;
        // setTimeout(()=>{
        //   // this.$refs.treeSelect
        // },100)
        this.$forceUpdate();
        console.info(this.storageForm.parentId)
      }
      console.info(nodeLevel);
    },
    // listArr: function (treeData, nodeId, defaultLevel, result) {
    //   if (treeData === null || treeData.length < 0) {
    //     result = -1;
    //     return false;
    //   }
    //   let targetResult = treeData.filter(res => res.id === nodeId);
    //   if (targetResult.length === 1) {
    //     console.info("find node")
    //     result = defaultLevel
    //     return true;
    //   } else {
    //     for (let i1 = 0; i1 < treeData.length; i1++) {
    //       const node = treeData[i1];
    //       if (node.children != null && node.children.length > 0) {
    //         for (let i = 0; i < node.children.length; i++) {
    //           if (this.listArr(node.children, nodeId, defaultLevel + 1, result)) {
    //             return true;
    //           }
    //         }
    //       }
    //     }
    //   }
    //   return false;
    // },
  }
}
</script>
<style lang="scss">

.storage-add-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .equipmentFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .equipment-main-form {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }

  .equipment-query-form {
    .el-form-item__label {
      padding: 0;
    }
  }
}
</style>
