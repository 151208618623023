<template>
	<div class="equipment-list-container">
		<DsnPanel>
			<div slot="header" class="title clearfix">
				<el-form :inline="true" :model="queryParam" ref="equipmentFormRef" :rules="{}" @submit.native.prevent>
					<el-row>
						
						<el-col :span="6">
							<el-form-item :label="this.$t('equipments.fields.mainCategory') + ':'" prop="mainCategory_id" label-width="80px">
								<dsn-select
									 style="width: 250px;"
									v-model.trim="queryParam.mainCategory_id"
									filterable
									@change="onMainChange"
									:placeholder="this.$t('commmsg.pleaseSelect')+' '+this.$t('equipments.fields.mainCategory')"
									size="medium"
								>
									<el-option v-for="item in mainCategoryOptionList" :key="item.id" :label="item.categoryName" :value="item.id"></el-option>
								</dsn-select>
							</el-form-item>
						</el-col>
						
						
						<el-col :span="6">
							<el-form-item :label="this.$t('equipments.fields.middleCategory') + ':'" prop="category_id" label-width="80px">
								<dsn-select
								 style="width: 250px;"
									v-model.trim="queryParam.category_id"
									filterable
									@change="onMiddleChange"
									:placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.middleCategory')}`"
									size="medium"
								>
									<el-option v-for="item in middleCategoryOptionList" :key="item.id" :label="item.categoryName" :value="item.id"></el-option>
								</dsn-select>
							</el-form-item>
						</el-col>
						<el-col :span="6">
							<el-form-item :label="this.$t('equipments.fields.subcategory') + ':'" prop="subCategory_id" label-width="80px">
								<dsn-select
									 style="width: 250px;"
									v-model.trim="queryParam.subCategory_id"
									filterable
									:placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.subcategory')}`"
									size="medium"
								>
									<el-option v-for="item in subcategoryOptionList" :key="item.id" :label="item.categoryName" :value="item.id"></el-option>
								</dsn-select>
							</el-form-item>
						</el-col>
						
						
						

						
						
						
					</el-row>
					
					<el-row>
						<el-col :span="6">
							<el-form-item :label="this.$t('commmsg.categoryname') + ':'" prop="name" label-width="80px">
								<dsn-input  style="width: 250px;" v-model="queryParam.name" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('commmsg.categoryname')"></dsn-input>
							</el-form-item>
						</el-col>
						
						<el-col :span="6">
							<el-form-item :label="this.$t('equipments.fields.brand') + ':'" prop="brand_name" label-width="80px">
								<dsn-input  style="width: 250px;" v-model="queryParam.brand_name" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.brand')"></dsn-input>
							</el-form-item>
						</el-col>
						
						<el-col :span="6">
							<el-form-item :label="this.$t('equipments.fields.modelType') + ':'" prop="model_type" label-width="80px">
								<dsn-input  style="width: 250px;" v-model="queryParam.model_type" :placeholder="this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.modelType')"></dsn-input>
							</el-form-item>
						</el-col>
						
					</el-row>
					
					
					
					
					
					
					<el-form-item>
						<dsn-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery(['equipmentFormRef'])">{{ this.$t('commbtn.query') }}</dsn-button>
						<dsn-button size="medium" type plain icon="el-icon-refresh" class="reset-button" @click="handleReset('equipmentFormRef')">
							{{ this.$t('commbtn.reset') }}
						</dsn-button>

						<dsn-button size="medium" type="success" icon="el-icon-folder-add" @click.native="handleAdd" v-if="isAddBtn == true">
							{{ this.$t('commbtn.add') }}
						</dsn-button>
						<dsn-button
							size="medium"
							type="primary"
							icon="el-icon-edit"
							:disabled="(selectionList.length+selectionList2.length) != 1"
							@click="handleEdit"
							v-if="isEditBtn == true"
							style="margin-left:0"
						>
							{{ this.$t('commbtn.edit') }}
						</dsn-button>
						
						
						
						
						
					</el-form-item>
				</el-form>
			</div>

			<!-- <dsn-table :row-key="row => { return row.equipment_subcategory_id }"
				:tree-props="{children: 'detailList'}"-->
			<el-table
				v-loading="pagingConfig.tableLoading"
				ref="multipleTable"
				:data="pagingConfig.tableData"
				tooltip-effect="dark"
				height="540"
				style="width: 100%;"
				stripe
				border
				
				@selection-change="handleSelectionChange"
				:header-cell-style="{ 'font-size': '17px', background: '#4e81bd', 'font-family': '微软雅黑', 'font-weight': 'normal', color: '#ffffff' }"
				:row-style="{ background: '#f9f9f9' }"
				:cell-style="{ 'font-size': '14px', 'font-family': '微软雅黑' }"
				v-adaptive="{bottomOffset: 70}"
				class="dsn-table"
				:default-expand-all="false"
			>
				
				<!-- <el-table-column type="selection" width="55" align="center" /> @row-click="selectCheckBox"-->
				
				
				<el-table-column type="expand" width="55">
				      <template slot-scope="props">
				        <el-table
				        	ref="multipleTable2"
				        	:data="props.row.detailList"
				        	tooltip-effect="dark"
				        	style="width: 100%;"
				        	stripe
							:show-header="false"
				        	@selection-change="handleSelectionChange2"
				        	:row-style="{ background: '#f9f9f9' }"
				        	:cell-style="{ 'font-size': '14px', 'font-family': '微软雅黑' }"
				        	v-adaptive="{bottomOffset: 70}"
				        	class="dsn-table"
							
				        >
				        	
				        	<el-table-column type="selection" width="55" align="center" />
				        	
				        	<el-table-column prop="" show-overflow-tooltip width="120" align="left"/>
				        	<el-table-column prop="" show-overflow-tooltip width="120" align="left"/>
				        	<el-table-column prop="" show-overflow-tooltip width="180" align="left"/>
				        	<el-table-column prop="name" show-overflow-tooltip width="260" align="left" />
				        	<el-table-column prop="brand_name" show-overflow-tooltip width="100" align="left" />
							<el-table-column prop="model_type" show-overflow-tooltip width="250" align="left" />
							<!-- <el-table-column align="left">
								<template>
									<dsn-button
										size="medium"
										type="primary"
										icon="el-icon-document"
										@click="handleEdit"
									>
										详情
									</dsn-button>
								</template>
							</el-table-column> -->
				        </el-table>
				      </template>
				    </el-table-column>
					
					
				<el-table-column prop="equipment_main_category_name" show-overflow-tooltip :label="this.$t('equipments.fields.mainCategory')" width="120" align="left"/>
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				
				<el-table-column prop="equipment_category_name" show-overflow-tooltip :label="this.$t('equipments.fields.middleCategory')" width="120" align="left"/>
				<el-table-column prop="equipment_subcategory_name" show-overflow-tooltip :label="this.$t('equipments.fields.subcategory')" width="180" align="left"/>
				<el-table-column prop="name" show-overflow-tooltip :label="this.$t('commmsg.categoryname')" width="260" align="left" />
				<el-table-column prop="brand_name" show-overflow-tooltip :label="this.$t('equipments.fields.brand')" width="100" align="left" />
				
				<el-table-column prop="model_type" show-overflow-tooltip :label="this.$t('equipments.fields.modelType')" width="255" align="left" />
				
				<!-- <el-table-column align="left" width="230">
					<template>
						<dsn-button
							size="medium"
							type="primary"
							icon="el-icon-document"
							@click="handleEdit"
						>
							{{ this.$t('commbtn.detail') }}
						</dsn-button>
					</template>
				</el-table-column> -->
				
				
				
			</el-table>

			<!-- <DsnFooter>
				<dsn-pagination
					v-if="showPage"
					background
					layout="->,total,prev,pager,next,sizes"
					:total="pagingConfig.count"
					:page-size="pagingConfig.pageSize"
					:page-sizes="[5, 10, 15, 20]"
					:current-page="pagingConfig.current"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentChange"
				></dsn-pagination>
			</DsnFooter> -->
		</DsnPanel>
		<el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
			<span>是否确认删除{{ selectionList.length }}条数据？</span>
			<span slot="footer" class="dialog-footer">
				<dsn-button @click="deleteDialog = false">取 消</dsn-button>
				<dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
			</span>
		</el-dialog>

		
	</div>
</template>
<script>
import { mapMutations } from 'vuex';
import DsnFooter from '@/views/layout/dsn-footer';
import { deleteOneEquipmentById, exportEquipmentExcel, getEquipmentList, SapAccept,GetMainProfileList } from '@/api/efms/equipments/equipments.api';
import DsnPanel from '@/components/global/PageLayout/DsnPanel';
import { getStorageList } from '@/api/efms/equipments/equipment.storage.api';
import arrayToTree from 'array-to-tree';
import { printBarcode } from '@/api/efms/equipments/equipment.tag.api';
import { applyEquipmentTransfer } from '@/api/efms/equipments/equipment.transfer.api';
import { applyEquipmentShift } from '@/api/efms/equipments/equipment.shift.api';
import { getUserList } from '@/api/efms/users/users.api';
import { getEquipmentCategoryList } from '@/api/efms/equipments/equipment.category.api';

export default {
	name: 'MainGear',
	components: { DsnPanel, DsnFooter },
	inject: ['defaltDialogWidth'],
	data() {
		return {
			listData: [],
			transferDialog: false,
			twoDate:[],
			queryParam: {
				mainCategory_id: null,
				category_id: null,
				subCategory_id: null,
				name: null,
				brand_name: null,
				model_type:null,
			},
			deviceStateoptions:[
				{
					label:'使用中',
					value:1,
				},
				{
					label:'闲置',
					value:2,
				},
				{
					label:'淘汰',
					value:3,
				},
				{
					label:'报废',
					value:4,
				}
			],
			
			sapStateoptions:[
				{
					label:'未验收',
					value:0,
				},
				{
					label:'已签收',
					value:1,
				}
			],

			showPage: true,
			pagingConfig: {
				current: 1,
				pageSize: 10,
				count: 0,
				tableLoading: false,
				total: 0
			},
			deleteDialog: false,
			selectionList: [],
			selectionList2:[],
			printDataList: [
				{
					companyName: '公司名称'
				}
			],
			printForm: {
				name: ''
			},
			codeImageList: [],
			storageFullList: [],
			transferForm: {
				applyUserId: null,
				applyUserName: null,
				modelType: null,
				mainCategoryId: null,
				middleCategoryId: null,
				subcategoryId: null,
				equipmentId: null,
				applicant: null,
				toApproveBy: null,
				equipmentName: null,
				storageId: null,
				targetStorageId: null
			},
			defaultTransferForm: {},
			userList: [],
			transferFlag: true,
			middleCategoryOptionList: [],
			subcategoryOptionList: [],
			mainCategoryOptionList:[],
			fullStorageTree: [],
			selectParams: {
				multiple: false,
				clearable: true,
				placeholder: '请选择'
			},
			treeParams: {
				clickParent: true,
				filterable: false,
				'check-strictly': true,
				'default-expand-all': false,
				'expand-on-click-node': false,
				data: [],
				props: {
					children: 'children',
					label: 'position',
					// disabled: 'disabled',
					value: 'id'
				}
			},

			isAddBtn: false,
			isEditBtn: false,
			isApplyBtn: false,
			isPrintBtn: false,
			isExportBtn: false,
			isAcceptBtn: false,
			isDelBtn: false
		};
	},
	computed: {},
	created() {
		this.initFullCategoryList();
		this.initFullStorageList();
		this.getData();

		var permissionsList = JSON.parse(localStorage.getItem('USER_PERMISSIONS'));

		if (permissionsList.indexOf('api:equipments:viewExport') != -1) {
			this.isExportBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:addPrint') != -1) {
			this.isAddBtn = true;
			this.isPrintBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:transfer') != -1) {
			this.isApplyBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:sapAccept') != -1) {
			this.isAcceptBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:edit') != -1) {
			this.isEditBtn = true;
		}
		if (permissionsList.indexOf('api:equipments:del') != -1) {
			this.isDelBtn = true;
		}
	},
	activated() {
		this.initFullCategoryList();
		this.initFullStorageList();
		this.getData();
	},
	methods: {
		dateChange(e) {
			this.queryParam.startDate = e[0];
			this.queryParam.endDate = e[1];
		},
		SapAccept() {
			console.log(this.selectionList);
			var list = [];
			this.selectionList.forEach(obj => {
				list.push(obj.id);
			});
			let params = { list: list };
			SapAccept(params).then(projectRes => {
				console.log(projectRes);
				if (projectRes.data.resultCode == 0) {
					this.notification().success({
						body: projectRes.data.message
					});
					this.getData();
				} else {
					this.notification().error({
						body: projectRes.data.errMsg
					});
				}
			});
		},

		...mapMutations(['EQUIPMENTLIST']),
		handleQuery(formNames) {			
			this.$refs[formNames].validate(valid => {
				if (valid) {
					this.pagingConfig.current = 1;
					this.initMainList(false);
				} else {
					return false;
				}
			});
		},
		handleReset(formName) {
			this.$refs[formName].resetFields();
			this.handleQuery(formName);
		},
		handlePageSizeChange(pageSize) {
			this.pagingConfig.pageSize = pageSize;
			this.pagingConfig.current = 1;
			this.initMainList();
		},
		handleCurrentChange(currentChange) {
			this.pagingConfig.current = currentChange;
			console.info(this.pagingConfig.current);
			this.initMainList(true);
		},
		handleSelectionChange(val) {
			this.selectionList = val;
			console.log('this.selectionList ------------------------',this.selectionList);
		},
		handleSelectionChange2(val){
			this.selectionList2 = val;
			console.log('this.selectionList2 ------------------------',this.selectionList2);
		},
		selectCheckBox(row) {
			this.$refs.multipleTable.toggleRowSelection(row);
		},
		handleEdit() {
			if(this.selectionList.length>0){
				const tempArr = JSON.parse(JSON.stringify(this.selectionList));
				this.EQUIPMENTLIST(tempArr);
				localStorage.setItem('equipmentList', JSON.stringify(this.selectionList));
				this.$router.push({
					name: 'mainEquipmentEdit',
					query: { operateType: 'edit' }
				});
			}
			else if(this.selectionList2.length>0){
				const tempArr = JSON.parse(JSON.stringify(this.selectionList2));
				this.EQUIPMENTLIST(tempArr);
				localStorage.setItem('equipmentList', JSON.stringify(this.selectionList2));
				this.$router.push({
					name: 'mainEquipmentEdit',
					query: { operateType: 'edit' }
				});
			}
			
		},
		handleAdd() {
			// const tempArr = JSON.parse(JSON.stringify(this.selectionList));
			this.EQUIPMENTLIST([]);
			// localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
			this.$router.push({
				name: 'mainEquipmentAdd',
				query: { operateType: 'add' }
			});
		},
		handleDelete() {
			const { selectionList } = this;
			console.info(selectionList);
			let that = this;
			if (selectionList.length === 1) {
				deleteOneEquipmentById(selectionList[0].id)
					.then(res => {
						console.info(res);
						const { status, data } = res;
						if (status === 200 && data != null && data.resultCode === 0) {
							that.initMainList(true);
							this.$message.success('删除成功');
						} else {
							this.$message.error(`删除失败${data.errMsg}`);
						}
					})
					.catch(err => {
						this.$message.error('删除失败');
					});
			} else {
				this.$message.error('请选择一条要删除的记录');
			}
		},
		handleDeleteCheck() {
			this.messageBox()
				.then(() => {
					this.handleDelete();
				})
				.catch(() => {
					this.notification().warn({
						body: '已经取消删除'
					});
				});
		},
		getData() {
			this.initUserList();
			this.initMainList(true);
			this.initStorageList();
		},

		initUserList() {
			getUserList({
				page: 0,
				pageSize: 0
			}).then(response => {
				const { data, status } = response;
				if (status === 200) {
					this.userList = [...data.data];
				} else {
					this.notification().warn({
						body: data.errMsg
					});
				}
			});
		},
		initMainList(showPage) {
			// if (!showPage) {
			// 	this.pagingConfig.current = 1;
			// }
			const data = {
				...this.queryParam,
				isTree:true,
				// page: 1,
				// pageSize: 999999
			};
			this.showPage = showPage;
			this.pagingConfig.tableLoading = true;
			GetMainProfileList(data).then(response => {
				const { status, errMsg, data } = response;
				if (status === 200) {
					if (data != null && data.data.length > 0) {
						this.showPage = true;
						this.pagingConfig.count = data.totalCount;
						let dataList = data.data;
						if (dataList != null && dataList.length > 0) {
							// for (let i = 0; i < dataList.length; i++) {
							// 	let item = dataList[i];
							// 	if (item.imgIds != null) {
							// 		let imageUrl = this.convertEquipmentImage(item.imgIds);
							// 		if (imageUrl != null && imageUrl.length > 0) {
							// 			Object.assign(item, {
							// 				imagePreview: imageUrl[0],
							// 				imageList: imageUrl
							// 			});
							// 		}
							// 	}
							// }
							this.pagingConfig.tableData = [...dataList];
						} else {
							this.pagingConfig.tableData = [];
						}
						this.$forceUpdate();
					} else {
						this.showPage = false;
						this.pagingConfig.count = 0;
						this.pagingConfig.tableData = [];
						this.$forceUpdate();
					}
				} else {
					this.notification().warn({
						body: errMsg
					});
				}
				this.pagingConfig.tableLoading = false;
			});
		},
		conditionFormatter(value) {
			if (value != null) {
				// 1. 使用中
				// 2. 闲置
				// 3.淘汰
				// 4. 报废
				switch (value) {
					case '1':
						return '使用中';
					case '2':
						return '闲置';
					case '3':
						return '淘汰';
					case '4':
						return '报废';
					default:
						return '';
				}
			} else {
				return '';
			}
		},
		printEquipmentQrCode() {
			let selectedPrintList = this.selectionList;
			if (selectedPrintList != null && selectedPrintList.length > 0) {
				let ids = selectedPrintList.map(v => {
					return v.id;
				});
				printBarcode(ids).then(res => {
					const { status } = res;
					if (status === 200) {
						this.$message.success('提交成功');
					}
				});
			}
		},
		initStorageList() {
			//获取主分类下拉框
			let postData = {
				page: 0,
				pageSize: 0
			};
			getStorageList(postData).then(res => {
				const { status, data } = res;
				if (status != null && data != null && data.data.length > 0) {
					this.storageFullList = [...data.data];
					let tempList = data.data.map(v => {
						return {
							position: v.equipmentStorageName,
							id: v.id,
							costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
							parentId: v.parentId == null || v.parentId === '' ? null : v.parentId,
							keeperId: v.keeperId,
							keeperName: v.keeperName,
							children: null
						};
					});
					let resultList = arrayToTree(tempList, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					this.treeParams.data = [...resultList];
					// this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
					this.storageNodeList = [...resultList];
				} else {
					this.storageNodeList = [];
					this.treeParams.data = [];
					// this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
				}
			});
		},
		showTransferApply(flag) {
			this.transferFlag = flag;
			console.info(flag);
			if (this.selectionList != null && this.selectionList.length === 1) {
				let profile = localStorage.getItem('userProfile');
				let userProfile = null;
				if (profile != null && profile.length > 0) {
					userProfile = JSON.parse(profile);
				}
				this.transferDialog = true;
				this.transferForm = this.selectionList[0];
				Object.assign(this.transferForm, {
					applyUserId: userProfile == null ? null : userProfile.id,
					applyUserName: userProfile == null ? null : userProfile.realname,
					mainCategoryId: this.transferForm.equipmentMainCategoryId,
					mainCategoryName: this.transferForm.equipmentMainCategoryName,
					middleCategoryId: this.transferForm.equipmentCategoryId,
					middleCategoryName: this.transferForm.equipmentCategoryName,
					subcategoryName: this.transferForm.equipmentSubcategoryName,
					subcategoryId: this.transferForm.equipmentSubcategoryId
				});
				const { storageId } = this.transferForm;
				if (storageId != null && storageId !== '') {
					let targetStorages = this.storageFullList.filter(s => s['id'] === storageId);
					if (targetStorages != null && targetStorages.length === 1) {
						let keepers = targetStorages[0].keeperId;
						if (keepers != null && keepers.length > 0) {
							if (keepers.indexOf(',') > -1) {
								let users = keepers.split(',');
								let nameArrays = [];
								Object.assign(this.transferForm, {
									storageManagerId: keepers
								});
								if (users != null && users.length > 0) {
									users.forEach(v => {
										let targetUser = this.userList.filter(s => s.id === v);
										if (targetUser != null && targetUser.length > 0) {
											nameArrays.push(targetUser[0].realname);
										}
									});
								}
								Object.assign(this.transferForm, {
									storageManagerName: nameArrays.join(','),
									storageManagerId: keepers
								});
							} else {
								let targetUser = this.userList.filter(s => s.id === keepers);
								if (targetUser != null && targetUser.length > 0) {
									Object.assign(this.transferForm, {
										storageManagerId: targetUser[0].id,
										storageManagerName: targetUser[0].realname
									});
								}
							}
						}
					}
				}
			}
			console.info(this.transferForm);
		},
		onApplyClose() {
			this.transferDialog = false;
		},
		onApplySubmit() {
			let formData = JSON.parse(JSON.stringify(this.transferForm));
			console.info(formData);
			if (this.transferForm.storageId === null || this.transferForm.storageId === undefined || this.transferForm.storageId === '') {
				this.$message.error('请设置设备储位');
				return;
			}
			if (this.transferForm.storageManagerId === null || this.transferForm.storageManagerId === undefined || this.transferForm.storageManagerId === '') {
				this.$message.error('请设置储位保管人');
				return;
			}
			if (this.transferForm.targetStorageId === null || this.transferForm.targetStorageId === undefined || this.transferForm.targetStorageId === '') {
				this.$message.error('请设置目标储位');
				return;
			}
			if (this.transferForm.targetStorageManagerId === null || this.transferForm.targetStorageManagerId === undefined || this.transferForm.targetStorageManagerId === '') {
				this.$message.error('请设置目标储位保管人');
				return;
			}
			if (this.transferForm.applyUserId === null) {
				this.$message.error('调拨申请人id不能为空');
				return;
			}
			if (this.transferForm.targetStorageId != null && this.transferForm.storageId != null && this.transferForm.targetStorageId === this.transferForm.storageId) {
				this.$message.error('储位相同无法调拨或转移');
				return;
			}
			let postDat = {
				// transferNo: new Date().getTime(),
				mainCategoryId: formData['mainCategoryId'] == null || formData['mainCategoryId'] === undefined ? null : formData['mainCategoryId'],
				mainCategoryName: formData['mainCategoryName'] == null || formData['mainCategoryName'] === undefined ? null : formData['mainCategoryName'],
				middleCategoryId: formData['middleCategoryId'],
				middleCategoryName: formData['middleCategoryName'],
				subcategoryId: formData['subcategoryId'],
				subcategoryName: formData['subcategoryName'],
				applyUserId: formData['applyUserId'],
				equipmentId: formData['id'],
				equipmentName: formData['name'],
				equipmentSno: formData['sno'],
				storageId: formData['storageId'],
				// storageName: formData['storageName'],
				storageManagerId: formData['storageManagerId'],
				// storageManager: formData['storageManagerName'],
				targetStorageId: formData['targetStorageId'],
				// targetStorageName: formData['targetStorageName'],
				targetStorageManagerId: formData['targetStorageManagerId'],
				// targetStorageManager: formData['targetStorageManagerName'],
				// applyDate: null,
				// confirmStatus: 'WAIT_TO_CONFIRM',
				remark: formData['remark']
			};
			if (this.transferFlag) {
				applyEquipmentTransfer(postDat)
					.then(res => {
						const { status, data } = res;
						if (status === 200 && data.resultCode === 0) {
							this.transferDialog = false;
							this.$message.success('提交成功');
						} else {
							this.$message.error(data.errMsg);
						}
					})
					.catch(err => {
						this.$message.error(err.errMsg);
					});
			} else {
				applyEquipmentShift(postDat)
					.then(res => {
						const { status, data } = res;
						if (status === 200 && data.resultCode === 0) {
							this.transferDialog = false;
							this.$message.success('提交转移成功');
						} else {
							this.$message.error(data.errMsg);
						}
					})
					.catch(err => {
						this.$message.error(err.errMsg);
					});
			}
		},
		onTargetStorageClick(event) {
			const { keeperId, keeperName, position } = event;
			if (keeperId != null) {
				let nameArray = [];
				if (keeperId.indexOf(',')) {
					let users = keeperId.split(',');
					if (users != null && users.length > 0) {
						users.forEach(v => {
							let targets = this.userList.filter(s => s.id === v);
							if (targets != null && targets.length > 0) {
								nameArray.push(targets[0].realname);
							}
						});
					}
				} else {
					let targets = this.userList.filter(s => s.id === keeperId);
					if (targets != null && targets.length > 0) {
						nameArray.push(targets[0].realname);
					}
				}
				console.log('nameArray',nameArray);
				Object.assign(this.transferForm, {
					targetStorageManagerId: keeperId,
					targetStorageManagerName: nameArray == null ? null : nameArray.join(','),
					targetStorageName: position
				});
			}
		},
		acceptStatusFormatter(data) {
			// console.log('++++++++++++++++++++++++++++',data);
			return data == null ? '未验收' : data === '' ? '未验收' : '已验收';
		},

		initFullCategoryList() {
			getEquipmentCategoryList({ page: 0, pageSize: 0 }).then(res => {
				console.info(res);
				const { status, data } = res;
				if (status === 200 && data.data != null && data.data.length > 0) {
					let fullTree = arrayToTree(data.data, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					if (fullTree != null && fullTree.length > 0) {
						console.info(fullTree);
						this.mainCategoryOptionList = [...fullTree];
					}
				}
			});
		},
		initFullStorageList() {
			let postData = {
				page: 0,
				pageSize: 0
			};
			getStorageList(postData).then(res => {
				const { status, data } = res;
				if (status != null && data != null && data.data.length > 0) {
					// this.storageFullList = [...data.data]
					let tempList = data.data.map(v => {
						return {
							position: v.equipmentStorageName,
							id: v.id,
							costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
							parentId: v.parentId == null || v.parentId === '' ? null : v.parentId,
							children: null
						};
					});
					let resultList = arrayToTree(tempList, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					if (resultList.length > 0) {
						resultList.forEach(item => {
							item.disabled = true;
							let nodeChildren = item.children;
							if (nodeChildren != null && nodeChildren.length > 0) {
								nodeChildren.forEach(secItem => {
									if (secItem != null && secItem.children != null && secItem.children.length > 0) {
										secItem.disabled = false;
										secItem.children.forEach(third => {
											Object.assign(third, {
												disabled:false
											});
										});
									}
								});
							}
						});
					}
					this.treeParams.data = [...resultList];
					// this.storageNodeList = [...resultList]
				} else {
					// this.storageNodeList = []
					this.treeParams.data = [];
				}
			});
		},
		onMainChange(value){
			let targetItems = this.mainCategoryOptionList.filter(s => s.id === value);
			if (targetItems != null && targetItems.length > 0) {
				let subList = targetItems[0].children == null ? [] : JSON.parse(JSON.stringify(targetItems[0].children));
				this.middleCategoryOptionList = [...subList];
			}
			
			
			// if (fullTree[0].children != null) {
			// 	this.middleCategoryOptionList = [...fullTree[0].children];
			// }
		},
		onMiddleChange(value) {
			console.info(value);
			let targetItems = this.middleCategoryOptionList.filter(s => s.id === value);
			if (targetItems != null && targetItems.length > 0) {
				let subList = targetItems[0].children == null ? [] : JSON.parse(JSON.stringify(targetItems[0].children));
				this.subcategoryOptionList = [...subList];
			}
		},
		exportEquipment(exportFull) {
			let params = {
				...this.queryParam
			};
			if (exportFull) {
				params = {
					page: 0,
					pageSize: 0
				};
			}
			exportEquipmentExcel(params).then(res => {
				const { status, data } = res;
				// let blob = new Blob(data)
				let url = window.URL.createObjectURL(data);
				let a = document.createElement('a');
				a.href = url;
				a.download = '设备信息.xlsx';
				a.click();
				window.URL.revokeObjectURL(url);
			});
		},
		convertEquipmentImage(imgIds) {
			let result = [];
			if (imgIds != null && imgIds.length > 0) {
				let temp = imgIds.map(x => {
					return `https://cpm.deartsz.com/api/equipments/download/${x}`;
				});
				result = [...temp];
			}
			return result;
		}
	}
};
</script>
<style lang="scss" scoped>
.equipment-list-container {
	.operation {
		width: 100%;
		height: 48px;

		.dsn-button {
			margin: 0 8px 0 0;
		}
	}
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}

.print-container {
	display: none;
}

.print-form {
	border-radius: 15px;
	height: 200px;
	width: 300px;
	border: 1px solid #000;

	.title {
		font-weight: bold;
		text-align: center;
	}

	.form_field {
		margin-left: 10px;
	}
}

.transfer-main-form {
	.el-form-item {
		margin: 0 24px 16px 0;

		.el-select {
			width: 320px;
		}

		.el-input {
			width: 320px;
		}
	}
}

.transfer-dialog-footer {
	text-align: center;
}
::v-deep .el-table__expanded-cell{
	padding: 0 !important;
}
</style>
