import request from "@/service/";


/**
 * 添加
 * @param data
 * @returns {*}
 */
export const addEquipmentBarcode = (data) => {
    return request.post(`${window.VUE_APP_URL}/api/refs/barcodes`, data);
}
/**
 * 修改
 * @param data
 * @returns {*}
 */
export const updateEquipmentBarcode = (data) => {
    return request.put(`${window.VUE_APP_URL}/api/refs/barcodes`, data);
}
/**
 * 列表查询
 * @param data
 * @returns {*}
 */
export const getEquipmentBarcodeList = (params) => {
    return request.get(`${window.VUE_APP_URL}/api/refs/barcodes`, {
        params: params
    });
}
/**
 * 根据id获取
 * @param data
 * @returns {*}
 */
export const getEquipmentCodeById = (id) => {
    return request.get(`${window.VUE_APP_URL}/api/refs/barcodes/${id}`,);
}
/**
 * 根据id删除
 * @param data
 * @returns {*}
 */
export const deleteOneBarcode = (id) => {
    return request.delete(`${window.VUE_APP_URL}/api/refs/barcodes/${id}`,);
}

export const printBarcode = (ids) => {
    return request.post(`${window.VUE_APP_URL}/api/equipments/qrCodes/print`, ids);
}
