import { render, staticRenderFns } from "./positionRefEdit.vue?vue&type=template&id=63b7a5fc&"
import script from "./positionRefEdit.vue?vue&type=script&lang=js&"
export * from "./positionRefEdit.vue?vue&type=script&lang=js&"
import style0 from "./positionRefEdit.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports