<template>
	<div class="transfer-list-container">
		<DsnPanel>
			<div slot="header" class="title clearfix">
				<el-form :inline="true" :model="queryParam" ref="testQueryForm" :rules="{}" @submit.native.prevent>
					<el-row>
						<el-col :span="8">
							<el-form-item :label="`${this.$t('transfer.fields.applyTime')}:`" prop="startDate" size="medium" label-width="80px">
								<el-date-picker v-model="queryParam.startDate" value-format="yyyy-MM-dd" style="width: 148px;" type="date" placeholder="开始日期"></el-date-picker>
								<span style="margin-left: 5px;margin-right: 5px">至</span>
								<el-date-picker value-format="yyyy-MM-dd" style="width: 148px;" v-model="queryParam.endDate" type="date" placeholder="结束日期"></el-date-picker>
							</el-form-item>
						</el-col>
						<!-- <el-col :span="8">
              <el-form-item :label="`${this.$t('equipments.fields.sno')}:`" prop="sno" size="medium"
                            label-width="80px">
                <dsn-input v-model="queryParam.sno" size="medium"
                           :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('equipments.fields.sno')}`"></dsn-input>
              </el-form-item>
            </el-col> -->

						<el-col :span="8">
							<el-form-item :label="this.$t('equipments.fields.fromstorage') + ':'" prop="storage_id" label-width="80px">
								<el-tree-select ref="treeSelect1" v-model="queryParam.storage_id" :selectParams="selectParams" :treeParams="treeParams1" :isSingle="true" />
							</el-form-item>
						</el-col>

						<el-col :span="8">
							<el-form-item :label="this.$t('equipments.fields.tostorage') + ':'" prop="target_storage_id" label-width="80px">
								<el-tree-select ref="treeSelect2" v-model="queryParam.target_storage_id" :selectParams="selectParams" :treeParams="treeParams2" :isSingle="true" />
							</el-form-item>
						</el-col>
					</el-row>

					<el-row>
						<el-col :span="8">
							<el-form-item :label="`${this.$t('transfer.fields.assetNo')}:`" prop="equipmentAssetNo" size="medium" label-width="80px">
								<dsn-input
									v-model="queryParam.equipmentAssetNo"
									size="medium"
									:placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('transfer.fields.assetNo')}`"
								></dsn-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item :label="`${this.$t('transfer.fields.equipmentName')}:`" prop="equipmentName" size="medium" label-width="80px">
								<dsn-input
									v-model="queryParam.equipmentName"
									size="medium"
									style="width: 220px;"
									:placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('transfer.fields.equipmentName')}`"
								></dsn-input>
							</el-form-item>
						</el-col>
						<el-col :span="8">
							<el-form-item :label="`${this.$t('transfer.fields.status')}:`" prop="finalConfirmResult" size="medium" label-width="80px">
								<dsn-select
									v-model.trim="queryParam.finalConfirmResult"
									filterable
									style="width: 220px;"
									:placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('transfer.fields.status')}`"
									size="medium"
								>
									<el-option v-for="item in finalResultList" :key="item.key" :label="item.label" :value="item.value"></el-option>
								</dsn-select>
							</el-form-item>
						</el-col>
					</el-row>
				</el-form>
				<div class="operation">
					<dsn-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery(['testQueryForm'])">{{ $t('commbtn.query') }}</dsn-button>
					<dsn-button size="medium" type plain icon="el-icon-refresh" class="reset-button" @click="handleReset(['testQueryForm'])">{{ $t('commbtn.reset') }}</dsn-button>
					<dsn-button size="medium" type="success" :disabled="selectionList.length <= 0" @click="confirmorder" v-if="isDelBtn == true">
						{{ $t('commbtn.confirmTask') }}
					</dsn-button>
					<dsn-button size="medium" type="danger" :disabled="selectionList.length <= 0" @click="overorder" v-if="isDelBtn == true">
						{{ $t('commbtn.endTask') }}
					</dsn-button>
				</div>
			</div>

			<dsn-table
				@row-click="selectCheckBox"
				ref="multipleTable"
				:data="pagingConfig.tableData"
				v-loading="pagingConfig.tableLoading"
				tooltip-effect="dark"
				style="width: 100%;"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55" align="center" />
				<el-table-column :label="this.$t('transfer.fields.transferNo')" align="center" width="150">
					<template scope="scope">
						{{ scope.row.transferNo == null ? scope.row.shiftNo : scope.row.transferNo }}
					</template>
				</el-table-column>
				<el-table-column prop="equipmentAssetNo" :label="this.$t('transfer.fields.assetNo')" align="center" width="150" />
				<el-table-column prop="equipmentName" :label="this.$t('transfer.fields.equipmentName')" show-overflow-tooltip align="left" width="200" />
				<el-table-column prop="modelType" :label="this.$t('transfer.fields.modelType')" show-overflow-tooltip align="left" width="200" />
				<el-table-column prop="storageName" :label="this.$t('transfer.fields.storageName')" show-overflow-tooltip align="center" min-width="200" />
				<el-table-column prop="confirmResult" :label="this.$t('transfer.fields.confirmResult')" align="center" min-width="130">
					<template scope="scope">
						{{ formatConfirmStatus(scope.row.confirmResult) }}
					</template>
				</el-table-column>
				<el-table-column prop="targetStorageName" :label="this.$t('transfer.fields.targetStorageName')" align="center" min-width="180" />
				<el-table-column prop="targetConfirmResult" :label="this.$t('transfer.fields.targetConfirmResult')" align="center" min-width="130">
					<template scope="scope">
						{{ formatConfirmStatus(scope.row.targetConfirmResult) }}
					</template>
				</el-table-column>
				<el-table-column prop="applyUserName" :label="this.$t('transfer.fields.applyUser')" align="center" width="120" />
				<el-table-column prop="applyDate" :label="this.$t('transfer.fields.applyTime')" align="center" width="150">
					<template scope="scope">
						{{ dateFormat(scope.row.applyDate) }}
					</template>
				</el-table-column>
				<el-table-column prop="storageManager" :label="this.$t('transfer.fields.storageManager')" align="center" width="180" />
				<!-- <el-table-column prop="confirmDate" :label="this.$t('transfer.fields.confirmDate')" align="center" width="150">
          <template scope="scope">
            {{ dateFormat(scope.row.confirmDate) }}
          </template>
        </el-table-column> -->

				<el-table-column prop="confirmDate" :label="this.$t('transfer.fields.confirmDate')" align="center" width="250">
					<template scope="scope">
						{{ dateFormat(scope.row.confirmDate) }}
					</template>
				</el-table-column>

				<el-table-column prop="targetStorageManager" :label="this.$t('transfer.fields.targetStorageManager')" align="center" width="280" />

				<el-table-column prop="targetConfirmDate" :label="this.$t('transfer.fields.targetConfirmDate')" align="center" width="250">
					<template scope="scope">
						{{ dateFormat(scope.row.targetConfirmDate) }}
					</template>
				</el-table-column>
			</dsn-table>
			<DsnFooter>
				<!-- <span style="font-size: 16px;margin: 0 30px 0 0;">共{{allTotal}}条</span> -->
				<dsn-pagination
					v-if="showPage"
					background
					layout="->,total,prev,pager,next,sizes"
					:total="pagingConfig.count"
					:page-size="pagingConfig.pageSize"
					:page-sizes="[5, 10, 15, 20]"
					:current-page="pagingConfig.current"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentChange"
				></dsn-pagination>
			</DsnFooter>

			<!-- <DsnFooter>
	   
       <dsn-pagination
           v-if="showPage"
           background
           layout="->,total,prev,pager,next,sizes"
           :total="pagingConfig.total"
           :page-size="pagingConfig.pageSize"
           :page-sizes="[5, 10, 15, 20]"
           :current-page="pagingConfig.current"
           @size-change="handlePageSizeChange"
           @current-change="handleCurrentChange"
       ></dsn-pagination>
     </DsnFooter> -->
		</DsnPanel>
		<el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
			<span>是否确认删除{{ selectionList.length }}条数据？</span>
			<span slot="footer" class="dialog-footer">
				<dsn-button @click="deleteDialog = false">取 消</dsn-button>
				<dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import DsnFooter from '@/views/layout/dsn-footer';
import { getEquipmentTransferList } from '@/api/efms/equipments/equipment.transfer.api';
import { getEquipmentCategoryList } from '@/api/efms/equipments/equipment.category.api';
import { getStorageList } from '@/api/efms/equipments/equipment.storage.api';
import arrayToTree from 'array-to-tree';
import { getEquipmentShiftList, EndShifts,FinishOrder } from '@/api/efms/equipments/equipment.shift.api';
import moment from 'moment';

export default {
	name: 'equipmentTransferList',
	components: { DsnFooter },
	inject: ['defaltDialogWidth'],
	data() {
		return {
			listData: [],
			queryParam: {
				sno: null,
				equipmentName: null,
				equipmentAssetNo: null,
				finalConfirmResult: null,
				startDate: null,
				endDate: null,
				applyTime: null,
				storage_id: null,
				target_storage_id: null
			},
			selectParams: {
				multiple: false,
				clearable: true,
				placeholder: '请选择'
			},
			treeParams1: {
				clickParent: true,
				filterable: false,
				'check-strictly': true,
				'default-expand-all': false,
				'expand-on-click-node': false,
				data: [],
				props: {
					children: 'children',
					label: 'position',
					// disabled: 'disabled',
					value: 'id'
				}
			},
			treeParams2: {
				clickParent: true,
				filterable: false,
				'check-strictly': true,
				'default-expand-all': false,
				'expand-on-click-node': false,
				data: [],
				props: {
					children: 'children',
					label: 'position',
					// disabled: 'disabled',
					value: 'id'
				}
			},
			showPage: true,
			pagingConfig: {
				current: 1,
				pageSize: 10,
				count: 0,
				tableLoading: false,
				total: 0,
				tableData: []
			},
			deleteDialog: false,
			selectionList: [],
			categoryFullList: [],
			finalResultList: [
				{
					label: '待确认',
					value: 'WAIT'
				},
				{
					label: '已确认',
					value: 'CONFIRMED'
				},
				{
					label: '已驳回',
					value: 'REJECTED'
				}
			],
			isDelBtn: false,

			allTotal: 0
		};
	},
	computed: {},
	created() {
		this.initCategoryList();
		this.initFullStorageList();
		// this.combineSearch();

		this.pagingConfig.tableLoading = true;
		setTimeout(() => {
			// this.initMainList();
			this.combineSearch();
		}, 1000);

		this.queryParam.finalConfirmResult = 'WAIT';

		var permissionsList = JSON.parse(localStorage.getItem('USER_PERMISSIONS'));

		if (permissionsList.indexOf('api:transferShift:finish') != -1) {
			this.isDelBtn = true;
		}
	},
	activated() {
		this.initCategoryList();
		this.initFullStorageList();
		this.pagingConfig.tableLoading = true;
		setTimeout(() => {
			// this.initMainList();
			this.combineSearch();
		}, 1000);
	},
	methods: {
		initFullStorageList() {
			let postData = {
				page: 0,
				pageSize: 0
			};
			getStorageList(postData).then(res => {
				const { status, data } = res;
				if (status != null && data != null && data.data.length > 0) {
					// this.storageFullList = [...data.data]
					let tempList = data.data.map(v => {
						return {
							position: v.equipmentStorageName,
							id: v.id,
							costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
							parentId: v.parentId == null || v.parentId === '' ? null : v.parentId,
							children: null
						};
					});
					let resultList = arrayToTree(tempList, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					if (resultList.length > 0) {
						resultList.forEach(item => {
							item.disabled = false;
							let nodeChildren = item.children;
							if (nodeChildren != null && nodeChildren.length > 0) {
								nodeChildren.forEach(secItem => {
									if (secItem != null && secItem.children != null && secItem.children.length > 0) {
										secItem.disabled = false;
										secItem.children.forEach(third => {
											Object.assign(third, {
												disabled: false
											});
										});
									}
								});
							}
						});
					}
					this.treeParams1.data = [...resultList];
					this.$refs.treeSelect1.treeDataUpdateFun(this.treeParams1.data);
					this.treeParams2.data = [...resultList];
					this.$refs.treeSelect2.treeDataUpdateFun(this.treeParams2.data);
					// this.storageNodeList = [...resultList]
				} else {
					// this.storageNodeList = []
					this.treeParams1.data = [];
					this.$refs.treeSelect1.treeDataUpdateFun(this.treeParams1.data);
					this.treeParams2.data = [];
					this.$refs.treeSelect2.treeDataUpdateFun(this.treeParams2.data);
				}
			});
		},

		// overorder() {
		// 	console.log(this.selectionList);
		// 	let params = {
		// 		id: this.selectionList[0].id,
		// 		creatE_USER: '',
		// 		modifY_USER: ''
		// 	};
		// 	EndShifts(params).then(listdata => {
		// 		console.log(listdata);
		// 		if (listdata.data.resultCode == 0) {
		// 			this.notification().success({
		// 				body: listdata.data.errMsg
		// 			});
		// 			this.combineSearch();
		// 		}
		// 	});
		// },
		confirmorder(){
			console.log(this.selectionList);
			var tempList = [];
			this.selectionList.forEach(obj=>{
				tempList.push(obj.id)
			})
			
			let params = {
				orderList: tempList,
				action:'ACTION_CONFIRM',
			};
			console.log(params);
			FinishOrder(params).then(listdata => {
				console.log(listdata);
				if (listdata.data.resultCode == 0) {
					this.notification().success({
						body: listdata.data.errMsg
					});
					this.combineSearch();
				}
			});
		},
		overorder() {
			console.log(this.selectionList);
			var tempList = [];
			this.selectionList.forEach(obj=>{
				tempList.push(obj.id)
			})
			
			let params = {
				orderList: tempList,
				action:'ACTION_REJECT',
			};
			console.log(params);
			FinishOrder(params).then(listdata => {
				console.log(listdata);
				if (listdata.data.resultCode == 0) {
					this.notification().success({
						body: listdata.data.errMsg
					});
					this.combineSearch();
				}
			});
		},

		handleQuery(formNames) {
			this.$refs[formNames].validate(valid => {
				if (valid) {
					this.pagingConfig.current = 1;
					// this.initMainList(true);
					this.combineSearch();
				} else {
					return false;
				}
			});
		},
		handleReset(formName) {
			console.log(formName);
			formName.forEach(element => {
				this.$refs[element].resetFields();
			});
			this.queryParam.endDate = null;
			this.handleQuery(formName[0]);
		},
		handlePageSizeChange(pageSize) {
			this.pagingConfig.pageSize = pageSize;
			this.pagingConfig.current = 1;
			this.combineSearch();
		},
		handleCurrentChange(currentChange) {
			this.pagingConfig.current = currentChange;
			this.combineSearch();
		},
		handleSelectionChange(val) {
			// console.log('val',val);
			this.selectionList = val;
		},
		selectCheckBox(row) {
			this.$refs.multipleTable.toggleRowSelection(row);
		},
		handleEdit() {},
		handleAdd() {},
		handleDelete() {},
		handleDeleteCheck() {
			this.messageBox()
				.then(() => {
					this.handleDelete();
				})
				.catch(() => {
					this.notification().warn({
						body: '已经取消删除'
					});
				});
		},
		initMainList(showPage) {
			if (!showPage) {
				this.pagingConfig.currentPage = 1;
			}
			const data = {
				...this.queryParam,
				page: this.pagingConfig.currentPage,
				pageSize: this.pagingConfig.pageSize
			};
			this.showPage = showPage;
			this.pagingConfig.tableLoading = true;
			getEquipmentTransferList(data).then(response => {
				const { data, status } = response;
				this.pagingConfig.tableLoading = false;
				if (status === 200) {
					this.showPage = true;
					this.pagingConfig.total = data.totalCount;
					let result = [];
					if (data.data != null && data.data.length > 0) {
						result = data.data.map(v => {
							return Object.assign(v, {
								mainCategoryName: v.mainCategoryId == null ? null : this.filterCategoryName(v.mainCategoryId),
								middleCategoryName: v.middleCategoryId == null ? null : this.filterCategoryName(v.middleCategoryId),
								subcategoryName: v.subcategoryId == null ? null : this.filterCategoryName(v.subcategoryId)
							});
						});
					}
					this.pagingConfig.tableData = [...result];
				} else {
					this.notification().warn({
						body: data.errMsg
					});
				}
			});
		},
		initCategoryList() {
			getEquipmentCategoryList({
				page: 0,
				pageSize: 0
			}).then(res => {
				const { data, status } = res;
				if (status === 200) {
					this.categoryFullList = [...data.data];
				}
			});
		},
		filterCategoryName(id) {
			if (this.categoryFullList == null || this.categoryFullList.length <= 0 || id === '') {
				return null;
			} else {
				let targetItems = this.categoryFullList.filter(v => v.id === id);
				if (targetItems != null && targetItems.length > 0) {
					return targetItems[0].categoryName;
				} else {
					return null;
				}
			}
		},
		formatConfirmStatus(value) {
			if (value == null || value === '') {
				return this.$t('transfer.status.wait');
			} else {
				let key = value.toString().toUpperCase();
				if ('WAIT_TO_CONFIRM' === key || 'WAIT' === key) {
					return this.$t('transfer.status.wait');
				} else if ('CONFIRMED' === key || 'CONFIRM' === key) {
					return this.$t('transfer.status.confirmed');
				} else if ('REJECTED' === key || 'REJECT' === key) {
					return this.$t('transfer.status.rejected');
				}
			}
		},
		dateFormat(value) {
			return value == null ? null : moment(value).format('YYYY-MM-DD HH:mm');
		},
		/**
		 * 搜索调拨转移单
		 */
		combineSearch() {
			// if (this.queryParam.storage_id != null && this.queryParam.target_storage_id != null && this.queryParam.storage_id == this.queryParam.target_storage_id) {
			// 	this.notification().warn({
			// 		body: this.$t('equipments.fields.err')
			// 	});
			// 	return;
			// }
			let postData = {
				...this.queryParam,
				page: this.pagingConfig.current,
				pageSize: this.pagingConfig.pageSize
			};
			let that = this;
			//    this.$axios.all([getEquipmentTransferList(postData), getEquipmentShiftList(postData)])
			//        .then((result) => {
			//          let transResult = result[0];
			//          let shiftResult = result[1];
			// this.allTotal = Number(result[0].data.totalCount) + Number(result[1].data.totalCount)
			//          let tempResultList = [];
			//          if (transResult != null) {
			//            const {status, data} = transResult;
			//            if (status != null && status === 200 && data.resultCode === 0) {
			//              if (data.data != null && data.data.length > 0) {
			//                data.data.forEach(v => {
			//                  tempResultList.push(v)
			//                })
			//              }
			//            }
			//          }
			//          if (shiftResult != null) {
			//            const {status, data} = shiftResult;
			//            if (status != null && status === 200 && data.resultCode === 0) {
			//              if (data.data != null && data.data.length > 0) {
			//                data.data.forEach(v => {
			//                  tempResultList.push(v)
			//                })
			//              }
			//            }
			//          }
			//          that.pagingConfig.tableData = tempResultList
			//          that.pagingConfig.tableLoading = false
			//        });

			this.$axios.all([getEquipmentShiftList(postData)]).then(result => {
				let shiftResult = result[0];
				// this.allTotal = Number(result[0].data.totalCount) + Number(result[1].data.totalCount)
				let tempResultList = [];
				// if (transResult != null) {
				//   const {status, data} = transResult;
				//   if (status != null && status === 200 && data.resultCode === 0) {
				//     if (data.data != null && data.data.length > 0) {
				//       data.data.forEach(v => {
				//         tempResultList.push(v)
				//       })
				//     }
				//   }
				// }
				if (shiftResult != null) {
					const { status, data } = shiftResult;
					if (status != null && status === 200 && data.resultCode === 0) {
						if (data.data != null && data.data.length > 0) {
							data.data.forEach(v => {
								tempResultList.push(v);
							});
						}
					}
					this.pagingConfig.count = data.totalCount;
				}

				that.pagingConfig.tableData = tempResultList;
				that.pagingConfig.tableLoading = false;
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.transfer-list-container {
	.operation {
		width: 100%;
		height: 48px;

		.dsn-button {
			margin: 0 8px 0 0;
		}
	}
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}

//::v-deep .el-form-item__label {
//  font-size: 25px;
//}
//
//::v-deep .el-form-item__content {
//  font-size: 25px;
//}
</style>
