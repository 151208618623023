<template>
  <div class="equipment-rental-container">
    <h1>设备设施借调与归还</h1>
  </div>
</template>
<script>
export default {
  name: 'equipmentRental',
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  methods: {},
}
</script>
<style lang="scss">

.equipment-rental-container {

}

</style>
