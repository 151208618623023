<template>
  <div class="storage-edit-container">
    <div class="operate">
      <dsn-button
          size="medium"
          icon="el-icon-back"
          type="primary"
          @click.native="handleBack"
      >{{ this.$t('commbtn.back') }}
      </dsn-button>
      <dsn-button-save size="medium"
                       @click.native="checkAdd(['storageFormRef'])">{{ this.$t('commbtn.save') }}
      </dsn-button-save
      >
      <dsn-button
          size="medium"
          type
          plain
          icon="el-icon-refresh"
          class="reset-button"
          @click.native="handleReset(['storageFormRef'])"
      >{{ this.$t('commbtn.reset') }}
      </dsn-button>
    </div>
    <div class="info">
      <el-form
          :model="storageForm"
          ref="storageFormRef"
          :inline="true"
          :rules="storageFormRules"
          class="equipment-main-form"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item :label="this.$t('storage.fields.parentNode')" prop="parentId"
                          :label-width="labelWidth">
              <!--              <storage-tree-select v-model="storageForm.parentId" :data="storageNodeList"-->
              <!--                                   @input="onStorageParentChange($event,storageForm)"-->
              <!--              />-->
              <el-tree-select ref="treeSelect" v-model="storageForm.parentId" :selectParams="selectParams"
                              :treeParams="treeParams"
                              :isSingle="true"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item :label="this.$t('storage.fields.storageName')" prop="storageName"
                        :label-width="labelWidth">
            <dsn-input
                v-model.trim="storageForm.storageName"
                placeholder="储位名称"
            ></dsn-input>
          </el-form-item>
        </el-row>

        <el-row>
          <el-form-item :label="this.$t('storage.fields.keeper')" prop="keeperId"
                        :label-width="labelWidth">
            <el-select filterable multiple v-model="storageForm.keeperId" placeholder="请选择保管人">
              <el-option
                  v-for="item in userOptionList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="this.$t('storage.fields.productGroupCode')" prop="productionGroupCode"
                        :label-width="labelWidth">
            <dsn-input
                v-model.trim="storageForm.productionGroupCode"
                placeholder="生产组代号"
            ></dsn-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="this.$t('storage.fields.productionAreaFlag')" prop="productionAreaFlag"
                        :label-width="labelWidth">
            <el-checkbox label="" :true-label="1" :false-label="0"
                         v-model="storageForm.productionAreaFlag" size="medium"></el-checkbox>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item :label="this.$t('storage.fields.costCenterNo')" prop="costCenterNo"
                        :label-width="labelWidth">
            <!-- <dsn-input
                v-model.trim="storageForm.costCenterNo"
                placeholder="成本中心"
            ></dsn-input> -->
			<el-select filterable v-model="storageForm.costCenterNo" placeholder="请选择成本中心">
			  <el-option
			      v-for="item in costList"
			      :key="item.id"
			      :label="item.Description"
			      :value="item.CostCenterName">
			  </el-option>
			</el-select>
          </el-form-item>
        </el-row>
      </el-form>
    </div>
  </div>

</template>
<script>

import StorageTreeSelect
  from "@/views/mes/basic_settings/equipments_position_ref_settings/components/storage-tree-select";
import {getUserList} from "@/api/efms/users/users.api";
import {getStorageList, updateStorage,CostCenter} from "@/api/efms/equipments/equipment.storage.api";
import {mapGetters} from "vuex";
import _ from "lodash";
import arrayToTree from "array-to-tree";

export default {
  name: 'storageEdit',
  components: {StorageTreeSelect},
  data() {
    return {
      isAddMainCategory: true,//初始化添加主类型的
      isEditStation: false, //是否可编辑站位
      storageForm: {
        id: null,
        storageName: null,
        costCenterNo: null,
        productionGroupCode: null,
        productionAreaFlag: 0,
        parentId: null,
        keeperId: null,
      },
      labelWidth: "120px",
      storageNodeList: [],
      storageCopyList: [],
      userOptionList: [],
      selectParams: {
        multiple: false,
        clearable: true,
        placeholder: '请选择'
      },
      treeParams: {
        clickParent: true,
        filterable: true,
        'check-strictly': true,
        'default-expand-all': true,
        'expand-on-click-node': false,
        data: [],
        props: {
          children: 'children',
          label: 'position',
          disabled: 'false',
          value: 'id'
        }
      },
	  costList:[],
    }
  },
  created() {
    this.operateType = this.$route.query.operateType;
    this.initSelectData();
	this.getCostCenter();
    this.cloneList = _.cloneDeep(JSON.parse(JSON.stringify(this.storageList)));
    this.storageForm =
        this.cloneList[0] ||
        JSON.parse(localStorage.getItem("storageList"))[0];
    let keeperId = this.storageForm.keeperId;
    if (keeperId == null) {
      this.storageForm.keeperId = [];
    } else {
      this.storageForm.keeperId = keeperId.split(",");
    }
  },
  computed: {
    ...mapGetters(["storageList"]),
    storageFormRules: function () {
      let that = this
      return {
        scheduleNo: [{required: true, message: "请输入盘点单号", trigger: "change"}],
        storageName: [{required: true, message: "请输入储位名称", trigger: "change"}],
        costCenterNo: [{required: false, message: "请输入成本中心", trigger: "change"}],
        keeperId: [{required: true, message: "请选择储位保管人", trigger: "change"}],
      }
    }
  },
  methods: {
	  
	  getCostCenter(){
	  		  let params = '';
	  		  CostCenter(params).then(costRes => {
	  		  	console.log(costRes);
	  		  	if (costRes.data.resultCode == 0) {
	  				this.costList = costRes.data.data
	  		  		// this.notification().success({
	  		  		// 	body: costRes.data.message
	  		  		// });
	  		  	} else {
	  		  		// this.notification().error({
	  		  		// 	body: costRes.data.errMsg
	  		  		// });
	  		  	}
	  		  });
	  },
	  
    handleBack() {
      this.$router.pushAndClear(
          {name: "equipmentStockRefSettings"},
          {
            deleteItem: this.$route,
            current: this.$route,
          }
      );
    },
    checkAdd(formName) {
      let count = 0;
      formName.forEach((element) => {
        this.$refs[element].validate((valid) => {
          if (valid) {
            count++;
          } else {
            return false;
          }
        });
      });
      if (count >= 1) {
        this.messageBox({
          type: "success",
          title: "保存",
          content: "是否保存数据?",
        })
            .then(() => {
              this.handleDataSave();
            })
            .catch(() => {
              this.notification().warn({
                body: "已取消保存",
              });
            });
      }
    },
    handleDataSave() {
      let postData = {};
      postData['id'] = this.storageForm.id;
      postData['parentId'] = this.storageForm.parentId;
      postData['equipmentStorageName'] = this.storageForm.storageName;
      postData['costCenterNo'] = this.storageForm.costCenterNo;
      postData['costCenterId'] = this.storageForm.costCenterNo;
      postData['productionAreaFlag'] = this.storageForm.productionAreaFlag;
      postData['productionGroupCode'] = this.storageForm.productionGroupCode;
      // postData['keeperId'] = this.storageForm.keeperId;

      if (this.storageForm.keeperId != null && this.storageForm.keeperId.length > 0) {
        let keeperIds = this.storageForm.keeperId.join(",");
        postData['keeperId'] = keeperIds;
      } else {
        postData['keeperId'] = null;
      }
      if (this.storageForm.keeperId != null && this.userOptionList.length > 0) {
        let targetItems = this.userOptionList.filter(s => s.value === this.storageForm.keeperId)
        if (targetItems != null && targetItems.length === 1) {
          postData['keeperName'] = targetItems[0].label
        }
      }
      updateStorage(postData).then(result => {
        const {status, errMsg, data} = result;
        if (status === 200) {
          let resultCode = data.resultCode;
          if (resultCode === 0) {
            this.notification().success({
              body: "保存成功",
            });
          }
          this.handleBack();
        } else {
          this.notification().error({
            body: errMsg,
          });
        }
      })
    },
    handleReset(formName) {
      if (this.operateType === "add") {
        formName.forEach((element) => {
          this.$refs[element].resetFields();
        });
      }
    },
    initSelectData() {
      this.initCategoryList();
    },
    initCategoryList() {
      this.getStorageMainList();
      this.getUserList();
    },
    getUserList() {
      let postData = {
        page: 0,
        pageSize: 0
      }
      getUserList(postData).then(res => {
        const {status, data} = res;
        if (status === 200 && data != null) {
          let resultList = data.data
          if (resultList != null && resultList.length) {
            this.userOptionList = resultList.map((v) => {
              return {
                id: v.id,
                label: v.realname + `(${v.username})`,
                value: v.id
              }
            })
          }
        } else {
          this.$message.error("获取用户列表失败")
        }
      })
    },
    getStorageMainList() {//获取主分类下拉框
      let postData = {
        page: 0,
        pageSize: 0
      }
      getStorageList(postData).then(res => {
        const {status, data} = res
        if (status != null && data != null && data.data.length > 0) {
          this.storageFullList = [...data.data]
          let tempList = data.data.map(v => {
            return {
              position: v.equipmentStorageName,
              id: v.id,
              costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
              parentId: v.parentId == null || v.parentId === "" ? null : v.parentId,
              children: null,
            }
          })
          let resultList = arrayToTree(tempList, {
            parentProperty: 'parentId',
            childrenProperty: 'children'
          })
          this.treeParams.data = [...resultList]
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
          this.storageNodeList = [...resultList]
        } else {
          this.storageNodeList = []
          this.treeParams.data = []
          this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
        }
      })
    },
    onStorageParentChange(event, formData) {
      console.info(event)
      console.info(formData)
      let selectId = event;
      let targetItem = this.storageCopyList.filter(s => s.id === selectId);
      if (targetItem != null && targetItem.length === 1) {
        let temp = targetItem[0]
        if (temp.id === formData.id && formData.id === formData.parentId) {
          this.storageForm.parentId = null
          this.$message.error("父节点不与当前节点相同")
        }
      }
    }
  }
}
</script>
<style lang="scss">

.storage-edit-container {
  .operate {
    padding: 16px;

    .el-button {
      margin: 0 8px 0 0;
    }
  }

  .info {
    background-color: #ffffff;
    padding: 16px;

    .equipmentFormTop {
      .el-form-item {
        margin: 0 24px 16px 0;
      }
    }

    .equipment-main-form {
      .el-form-item {
        margin: 0 24px 16px 0;

        .el-select {
          width: 320px;
        }

        .el-input {
          width: 320px;
        }
      }
    }
  }

  .equipment-query-form {
    .el-form-item__label {
      padding: 0;
    }
  }
}
</style>
