import request from "@/service/";


export const applyEquipmentTransfer = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/transfers/apply`,
        data
    );
}

export const confirmEquipmentTransfer = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/transfers/${data.id}/confirm`,
        data
    );
}

export const confirmTargetEquipmentTransfer = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/transfers/${data.id}/confirm`,
        data
    );
}

export const rejectEquipmentTransfer = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/transfers/${data.id}/reject`,
        data
    );
}


export const updateEquipmentTransferById = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/equipments/transfers/${data.id}`,
        data
    );
}


export const getEquipmentTransferList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/equipments/transfers`,
        {
            params: params
        }
    );
}

export const getEquipmentTransferById = (id) => {
    return request.get(
        `${window.VUE_APP_URL}/api/equipments/transfers/${id}`,
    );
}
