<template>
  <div class="equipment-check-schedule-container">
    <h1>设备设施维护计划</h1>
  </div>
</template>
<script>
export default {
  name: 'equipmentCheckSchedule',
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  methods: {},
}
</script>
<style lang="scss">

.equipment-check-schedule-container {

}

</style>
