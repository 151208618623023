<template>
	<div class="equipment-inventory-schedule-container">
		<DsnPanel>
			<div slot="header" class="title clearfix">
				<el-form :inline="true" :model="queryParam" ref="inventoryListRef" :rules="{}" @submit.native.prevent>
					<el-form-item :label="`盘点计划单号`" prop="scheduleNo">
						<dsn-input v-model="queryParam.scheduleNo" :placeholder="`请输入盘点计划单号`"></dsn-input>
					</el-form-item>
					<el-form-item :label="`盘点开始日期`">
						<!-- <el-date-picker
							v-model="queryParam.queryDateRange"
							type="daterange"
							value-format="yyyy-MM-dd"
							range-separator="至"
							start-placeholder="开始日期"
							end-placeholder="结束日期"
						></el-date-picker> -->
						<el-date-picker v-model="queryParam.startDate" value-format="yyyy-MM-dd" style="width: 192px;" type="date" placeholder="开始日期"></el-date-picker>
						<span style="margin-left: 5px;margin-right: 5px">至</span>
						<el-date-picker  :picker-options="pickerOptions" value-format="yyyy-MM-dd" style="width: 192px;margin-right: 20px;" v-model="queryParam.endDate" type="date" placeholder="结束日期"></el-date-picker>
					</el-form-item>
					<el-form-item>
						<dsn-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery(['inventoryListRef'])">{{ this.$t('commbtn.query') }}</dsn-button>
						<dsn-button size="medium" type plain class="reset-button" @click="handleReset(['inventoryListRef'])">{{ this.$t('commbtn.reset') }}</dsn-button>
						<!--            <dsn-button-->
						<!--                size="medium"-->
						<!--                type="primary"-->
						<!--                class="reset-button"-->
						<!--                @click="()=>{}"-->
						<!--            >{{ '开始盘点' }}-->
						<!--            </dsn-button>-->
						<!--            <dsn-button-->
						<!--                size="medium"-->
						<!--                plain-->
						<!--                class="reset-button"-->
						<!--                @click="()=>{}"-->
						<!--            >{{ '结束盘点' }}-->
						<!--            </dsn-button>-->
					</el-form-item>
				</el-form>
			</div>
			<div class="operation">
				<dsn-button size="medium" type="primary" icon="el-icon-folder-add" @click.native="handleAdd" v-if="isAddBtn == true">{{ this.$t('commbtn.add') }}</dsn-button>
			</div>

			<dsn-table
				@row-click="selectCheckBox"
				ref="multipleTable"
				:data="pagingConfig.tableData"
				tooltip-effect="dark"
				style="width: 100%"
				v-loading="pagingConfig.tableLoading"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55" align="center" />
				<el-table-column prop="scheduleNo" :label="this.$t('inventorySchedule.fields.scheduleNo')" width="200" align="center" />
				<el-table-column prop="applicant" :label="this.$t('inventorySchedule.fields.scheduleApplicant')" align="center" min-width="150">
					<template slot-scope="scope">
						<span>{{ scope.row.applicant == null ? null : scope.row.applicant.realname }}</span>
					</template>
				</el-table-column>

				<el-table-column prop="needToCount" :label="'应盘数量'" align="right" min-width="150" />
				<el-table-column prop="actualCount" :label="'实盘数量'" align="right" min-width="150" />
				<el-table-column prop="createTime" :label="'开始时间'" align="right" min-width="150">
					<template slot-scope="scope">
						<span>{{ formatDate(scope) }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="scheduleFinishDate" :label="this.$t('inventorySchedule.fields.confirmDate')" min-width="150" align="center" />
				<el-table-column prop="scheduleStatus" :label="this.$t('inventorySchedule.fields.scheduleStatus')" align="center" min-width="150">
					<template slot-scope="scope">
						<span v-if="scope.row.scheduleStatusId == null || scope.row.scheduleStatusId === '0'">{{ $t('inventorySchedule.constants.wait') }}</span>
						<span v-else-if="scope.row.scheduleStatusId != null && scope.row.scheduleStatusId === '1'">{{ $t('inventorySchedule.constants.checking') }}</span>
						<span v-else-if="scope.row.scheduleStatusId != null && scope.row.scheduleStatusId === '2'">{{ $t('inventorySchedule.constants.finished') }}</span>
						<span v-else-if="scope.row.scheduleStatusId != null && scope.row.scheduleStatusId === '3'">{{ $t('inventorySchedule.constants.aborted') }}</span>
						<span v-else>{{ $t('inventorySchedule.constants.unKnown') }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="'操作'" fixed="right" align="center" width="350px">
					<!--          <template s></template>-->
					<template slot-scope="scope">
						<dsn-button size="medium" type="primary" @click="handleViewDetail(scope.row)" v-if="isLookBtn == true" style="margin-left:0">{{ $t('commbtn.detail') }}</dsn-button>
						<!--            <dsn-button-->
						<!--                size="medium"-->
						<!--                type="primary"-->
						<!--                v-if="scope.row['scheduleStatusId']==='0'"-->
						<!--                @click="start(scope.row)"-->
						<!--                style="margin-left:0"-->
						<!--            >{{ $t('commbtn.startInventoryCheck') }}-->
						<!--            </dsn-button>-->
						<dsn-button size="medium" type="danger" v-if="scope.row['scheduleStatusId'] === '1' && isDelBtn == true" @click="finish(scope.row)" style="margin-left:0">
							{{ $t('commbtn.abortInventoryCheck') }}
						</dsn-button>
						<dsn-button size="medium" type="success" @click="exportInventoryReportClick(scope.row.id)" v-if="isExortBtn == true" style="margin-left:0">{{ '盘点报告' }}</dsn-button>
					</template>
				</el-table-column>
			</dsn-table>
			<DsnFooter>
				<dsn-pagination
					v-if="showPage"
					background
					layout="->,total,prev,pager,next,sizes"
					:total="pagingConfig.count"
					:page-size="pagingConfig.pageSize"
					:page-sizes="[5, 10, 15, 20]"
					:current-page="pagingConfig.current"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentChange"
				></dsn-pagination>
			</DsnFooter>
		</DsnPanel>
		<el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
			<span>是否确认删除{{ selectionList.length }}条数据？</span>
			<span slot="footer" class="dialog-footer">
				<dsn-button @click="deleteDialog = false">取 消</dsn-button>
				<dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { mapMutations } from 'vuex';
import DsnFooter from '@/views/layout/dsn-footer';
import {
	abortSchedule,
	deleteOneById,
	downloadInventoryReport,
	finishSchedule,
	getInventoryScheduleList,
	startSchedule
} from '@/api/efms/inventory_schedule/inventory_schedule.api';
import { getStorageList } from '@/api/efms/equipments/equipment.storage.api';
import arrayToTree from 'array-to-tree';
import moment from 'moment';

export default {
	name: 'equipmentInventorySchedule',
	components: { DsnFooter },
	inject: ['defaltDialogWidth'],
	data() {
		return {
			listData: [],
			queryParam: {
				scheduleNo: null,
				queryDateRange: null,
				startDate: null,
				endDate: null,
			},
			showPage: true,
			selectParams: {
				multiple: false,
				clearable: true,
				placeholder: '请选择'
			},
			treeParams: {
				clickParent: true,
				filterable: false,
				'check-strictly': true,
				'default-expand-all': true,
				'expand-on-click-node': false,
				data: [],
				props: {
					children: 'children',
					label: 'position',
					disabled: 'false',
					value: 'id'
				}
			},
			pagingConfig: {
				current: 1,
				pageSize: 10,
				count: 0,
				tableLoading: false,
				total: 0,
				tableData: []
			},
			deleteDialog: false,
			selectionList: [],
			storageNodeList: [],
			storageFullList: [],
			
			isAddBtn:false,
			isLookBtn:false,
			isDelBtn:false,
			isExortBtn:false,
		};
	},
	computed: {},
	created() {
		this.initData();
		
		var permissionsList = JSON.parse(localStorage.getItem("USER_PERMISSIONS"));
		
		
		if(permissionsList.indexOf("api:inventoryCheck:view") != -1){
			this.isLookBtn = true;
		}
		if(permissionsList.indexOf("api:inventoryCheck:addFinish") != -1){
			this.isAddBtn = true;
			this.isDelBtn = true;
		}
		if(permissionsList.indexOf("api:inventoryCheck:export") != -1){
			this.isExortBtn = true;
		}
		
	},
	activated() {
		this.initData();
	},
	methods: {
		...mapMutations(['INVENTORYSCHEDULELIST']),
		handleQuery(formNames) {
			this.$refs[formNames].validate(valid => {
				if (valid) {
					this.initData();
				} else {
					return false;
				}
			});
		},
		handleReset(formName) {
			// this.$refs[formName].resetFields();
			this.queryParam.scheduleNo = null;
			this.queryParam.startDate = null;
			this.queryParam.endDate = null;
			this.$forceUpdate();
			this.handleQuery(formName);
		},
		handlePageSizeChange(pageSize) {
			this.pagingConfig.pageSize = pageSize;
			this.pagingConfig.current = 1;
			this.initData();
		},
		handleCurrentChange(currentChange) {
			this.pagingConfig.current = currentChange;
			this.initMainList(true);
		},
		handleSelectionChange(val) {
			this.selectionList = val;
		},
		selectCheckBox(row) {
			this.$refs.multipleTable.toggleRowSelection(row);
		},
		handleEdit() {
			const tempArr = JSON.parse(JSON.stringify(this.selectionList));
			this.INVENTORYSCHEDULELIST(tempArr);
			localStorage.setItem('inventoryScheduleList', JSON.stringify(this.selectionList));
			this.$router.push({
				name: 'equipmentInventoryScheduleEdit',
				query: { operateType: 'edit' }
			});
		},
		handleViewDetail(data) {
			let dataList = [];
			dataList.push(data);
			const tempArr = JSON.parse(JSON.stringify(dataList));
			this.INVENTORYSCHEDULELIST(tempArr);
			localStorage.setItem('inventoryScheduleList', JSON.stringify(dataList));
			this.$router.push({
				name: 'equipmentInventoryScheduleEdit',
				query: { operateType: 'edit' }
			});
		},
		handleAdd() {
			// const tempArr = JSON.parse(JSON.stringify(this.selectionList));
			this.INVENTORYSCHEDULELIST([]);
			// localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
			this.$router.push({
				name: 'equipmentInventoryScheduleAdd',
				query: { operateType: 'add' }
			});
		},
		handleDelete() {
			const { selectionList } = this;
			const params = selectionList.map(item => ({
				id: item.id
			}));
			deleteOneById(params[0].id).then(res => {
				const { status, data } = res;
				if (status === 200 && data.resultCode === 0) {
					this.initData();
					this.notification().success({
						body: '删除成功'
					});
				} else {
					this.notification().error({
						body: data.errMsg
					});
				}
			});
		},
		handleDeleteCheck() {
			this.messageBox()
				.then(() => {
					this.handleDelete();
				})
				.catch(() => {
					// this.notification().warn({
					//   body: "已经取消删除"
					// });
				});
		},
		initData() {
			this.initMainList();
			this.getStorageMainList();
		},
		initMainList(showPage) {
			if (!showPage) {
				this.pagingConfig.current = 1;
			}
			console.info(this.queryParam);
			// let startDate = this.queryParam.queryDateRange != null ? this.queryParam.queryDateRange[0] : null;
			// let endDate = this.queryParam.queryDateRange != null ? this.queryParam.queryDateRange[1] : null;
			const data = {
				scheduleNo: this.queryParam.scheduleNo,
				// startDate: startDate == null ? null : endDate,
				// endDate: endDate == null ? null : endDate,
				startDate: this.queryParam.startDate,
				endDate: this.queryParam.endDate,
				
				page: this.pagingConfig.current,
				pageSize: this.pagingConfig.pageSize
			};
			this.showPage = showPage;
			this.pagingConfig.tableLoading = true;
			getInventoryScheduleList(data)
				.then(response => {
					const { code, errMsg, data, status } = response;
					if (status === 200) {
						this.showPage = true;
						this.pagingConfig.count = data.totalCount;
						this.pagingConfig.tableData = [...data.data];
						this.pagingConfig.tableLoading = false;
						return;
					}
				})
				.catch(err => {
					this.pagingConfig.tableLoading = false;
					// this.notification().warn({
					//   body: err
					// });
				});
		},
		getStorageMainList() {
			//获取主分类下拉框
			let postData = {
				page: 0,
				pageSize: 0
			};
			getStorageList(postData).then(res => {
				const { status, data } = res;
				if (status != null && data != null && data.data.length > 0) {
					this.storageFullList = [...data.data];
					let tempList = data.data.map(v => {
						return {
							position: v.equipmentStorageName,
							id: v.id,
							costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
							parentId: v.parentId == null || v.parentId === '' ? null : v.parentId,
							children: null
						};
					});
					let resultList = arrayToTree(tempList, {
						parentProperty: 'parentId',
						childrenProperty: 'children'
					});
					this.treeParams.data = [...resultList];
					// this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
					this.storageNodeList = [...resultList];
				} else {
					this.storageNodeList = [];
					this.treeParams.data = [];
					// this.$refs.treeSelect.treeDataUpdateFun(this.treeParams.data);
				}
			});
		},
		start(target) {
			if (target != null) {
				startSchedule(target)
					.then(res => {
						console.info(res);
						const { status, data } = res;
						if (status === 200 && data.resultCode === 0) {
							this.$message.success(`${this.$t('commmsg.success')}`);
							this.initMainList(true);
						} else {
							this.notification().error({
								body: data.errMsg
							});
						}
					})
					.catch(err => {});
			}
		},
		aborted() {
			let target = this.selectionList[0];
			if (target != null) {
				abortSchedule(target)
					.then(res => {
						console.info(res);
						const { status, data } = res;
						if (status === 200 && data.resultCode === 0) {
							this.$message.success(`${this.$t('commmsg.success')}`);
							this.initMainList(true);
						} else {
							this.notification().error({
								body: data.errMsg
							});
						}
					})
					.catch(err => {});
			}
		},
		finish() {
			let target = this.selectionList[0];
			if (target != null) {
				finishSchedule(target)
					.then(res => {
						console.info(res);
						const { status, data } = res;
						if (status === 200 && data.resultCode === 0) {
							this.$message.success(`${this.$t('commmsg.success')}`);
							this.initMainList(true);
						} else {
							this.notification().error({
								body: data.errMsg
							});
						}
					})
					.catch(err => {});
			}
		},
		formatDate(data) {
			return data == null ? null : moment(data.row.createTime).format('yyyy-MM-DD');
		},
		/***
		 * 导出盘点报告
		 * @param id
		 */
		exportInventoryReportClick(id) {
			downloadInventoryReport(id).then(res => {
				const { status, data } = res;
				// let blob = new Blob(data)
				let url = window.URL.createObjectURL(data);
				let a = document.createElement('a');
				a.href = url;
				a.download = '盘点报告.xlsx';
				a.click();
				window.URL.revokeObjectURL(url);
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.equipment-inventory-schedule-container {
	.operation {
		width: 100%;
		height: 48px;

		.dsn-button {
			margin: 0 8px 0 0;
		}
	}
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}
</style>
