<template>
	<div class="shift-list-container">
		<DsnPanel>
			<div slot="header" class="title clearfix">
				<el-form :inline="true" :model="queryParam" ref="testQueryForm" :rules="{}" @submit.native.prevent>
					<el-form-item :label="`${this.$t('equipments.fields.sno')}:`" prop="sno" size="medium">
						<dsn-input v-model="queryParam.sno" size="medium" :placeholder="`${this.$t('commmsg.pleaseInput') + ' ' + this.$t('equipments.fields.sno')}`"></dsn-input>
					</el-form-item>
					<!--          <el-form-item :label="`${this.$t('users.fields.realname')}:`" prop="realname" size="medium">-->
					<!--            <dsn-input v-model="queryParam.realname" size="medium"-->
					<!--                       :placeholder="`${this.$t('commmsg.pleaseInput')+' '+this.$t('users.fields.realname')}`"></dsn-input>-->
					<!--          </el-form-item>-->
					<el-form-item>
						<dsn-button size="medium" type="primary" icon="el-icon-search" @click="handleQuery(['testQueryForm'])">{{ $t('commbtn.query') }}</dsn-button>
						<dsn-button size="medium" type plain icon="el-icon-refresh" class="reset-button" @click="handleReset('transferList')">{{ $t('commbtn.reset') }}</dsn-button>
					</el-form-item>
				</el-form>
			</div>
			<!--      <div class="operation">-->
			<!--        <dsn-button-->
			<!--            size="medium"-->
			<!--            type="primary"-->
			<!--            icon="el-icon-edit"-->
			<!--            :disabled="selectionList.length !== 1"-->
			<!--            @click="handleEdit"-->
			<!--            style="margin-left:0"-->
			<!--        >{{ this.$t('commbtn.edit') }}-->
			<!--        </dsn-button>-->
			<!--      </div>-->
			<dsn-table
				@row-click="selectCheckBox"
				ref="multipleTable"
				:data="pagingConfig.tableData"
				v-loading="pagingConfig.tableLoading"
				tooltip-effect="dark"
				style="width: 100%;"
				@selection-change="handleSelectionChange"
			>
				<el-table-column type="selection" width="55" align="center" />
				<el-table-column prop="transferNo" :label="this.$t('transfer.fields.transferNo')" align="right" width="150" />
				<el-table-column prop="storageManager" :label="this.$t('transfer.fields.storageManager')" align="center" width="250" />
				<el-table-column prop="equipmentSno" :label="this.$t('equipments.fields.sno')" align="center" width="200" />
				<el-table-column prop="equipmentName" :label="this.$t('equipments.fields.name')" align="center" width="350" />
				<el-table-column prop="mainCategoryName" :label="this.$t('equipments.fields.mainCategory')" align="center" width="200" />
				<el-table-column prop="middleCategoryName" :label="this.$t('equipments.fields.category')" align="center" width="200" />
				<el-table-column prop="subcategoryName" :label="this.$t('equipments.fields.subcategory')" align="center" width="200" />
				<el-table-column prop="storageName" :label="this.$t('transfer.fields.storageName')" align="center" min-width="180" />
				<el-table-column prop="targetStorageName" :label="this.$t('transfer.fields.targetStorageName')" align="center" min-width="250" />
				<el-table-column prop="targetStorageManager" :label="this.$t('transfer.fields.targetStorageManager')" align="center" width="300" />
				<el-table-column prop="remark" :label="this.$t('transfer.fields.remark')" align="center" min-width="150" />
				<el-table-column prop="confirmDate" :label="this.$t('transfer.fields.confirmDate')" align="center" width="250" />
				<el-table-column prop="applyTime" :label="this.$t('transfer.fields.applyTime')" align="right" width="200" fixed="right" />
				<el-table-column prop="confirmResult" :label="this.$t('transfer.fields.applyTime')" align="center" width="150" fixed="right">
					<template slot-scope="scope">
						{{ scope.row.confirmResult == null ? '待确认' : formatConfirmStatus(scope.row.confirmResult) }}
					</template>
				</el-table-column>
			</dsn-table>
			<DsnFooter>
				<dsn-pagination
					v-if="showPage"
					background
					layout="->,total,prev,pager,next,sizes"
					:total="pagingConfig.total"
					:page-size="pagingConfig.pageSize"
					:page-sizes="[5, 10, 15, 20]"
					:current-page="pagingConfig.current"
					@size-change="handlePageSizeChange"
					@current-change="handleCurrentChange"
				></dsn-pagination>
			</DsnFooter>
		</DsnPanel>
		<el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
			<span>是否确认删除{{ selectionList.length }}条数据？</span>
			<span slot="footer" class="dialog-footer">
				<dsn-button @click="deleteDialog = false">取 消</dsn-button>
				<dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import DsnFooter from '@/views/layout/dsn-footer';
import { deleteUserById } from '@/api/efms/users/users.api';
import { getEquipmentCategoryList } from '@/api/efms/equipments/equipment.category.api';
import { getEquipmentShiftList } from '@/api/efms/equipments/equipment.shift.api';

export default {
	name: 'equipmentShiftList',
	components: { DsnFooter },
	inject: ['defaltDialogWidth'],
	data() {
		return {
			listData: [],
			queryParam: {
				username: null,
				realname: null
			},
			showPage: true,
			pagingConfig: {
				current: 1,
				pageSize: 10,
				count: 0,
				tableLoading: false,
				total: 0,
				tableData: []
			},
			deleteDialog: false,
			selectionList: [],
			categoryFullList: []
		};
	},
	computed: {},
	created() {
		this.initCategoryList();
		this.pagingConfig.tableLoading = true;
		setTimeout(() => {
			this.initMainList();
		}, 1000);
	},
	activated() {
		this.initCategoryList();
		this.pagingConfig.tableLoading = true;
		setTimeout(() => {
			this.initMainList();
		}, 1000);
	},
	methods: {
		handleQuery(formNames) {
			this.$refs[formNames].validate(valid => {
				if (valid) {
					this.pagingConfig.current = 1;
					this.initMainList(true);
				} else {
					return false;
				}
			});
		},
		handleReset(formName) {
			// this.$refs[formName].resetFields();
			formName.forEach(element => {
				this.$refs[element].resetFields();
			});
			this.handleQuery();
		},
		handlePageSizeChange(pageSize) {
			this.pagingConfig.pageSize = pageSize;
			this.pagingConfig.current = 1;
			this.initMainList(true);
		},
		handleCurrentChange(currentChange) {
			this.pagingConfig.current = currentChange;
			this.getSysUserList();
		},
		handleSelectionChange(val) {
			this.selectionList = val;
		},
		selectCheckBox(row) {
			this.$refs.multipleTable.toggleRowSelection(row);
		},
		handleEdit() {
			// const tempArr = JSON.parse(JSON.stringify(this.selectionList));
			// this.USERSLIST(tempArr);
			// localStorage.setItem("userList", JSON.stringify(this.selectionList));
			// this.$router.push({
			//   name: "userEdit",
			//   query: {operateType: "edit"}
			// });
		},
		handleAdd() {
			// const tempArr = JSON.parse(JSON.stringify(this.selectionList));
			// this.USERSLIST([]);
			// // localStorage.setItem("positionRefList", JSON.stringify(this.selectionList));
			// this.$router.push({
			//   name: "userAdd",
			//   query: {operateType: "add"}
			// });
		},
		handleDelete() {
			const { selectionList } = this;
			const params = selectionList.map(item => ({
				id: item.id
			}));
			deleteUserById(params).then(result => {});
		},
		handleDeleteCheck() {
			this.messageBox()
				.then(() => {
					this.handleDelete();
				})
				.catch(() => {
					this.notification().warn({
						body: '已经取消删除'
					});
				});
		},
		initMainList(showPage) {
			if (!showPage) {
				this.pagingConfig.currentPage = 1;
			}
			const data = {
				...this.queryParam,
				page: this.pagingConfig.currentPage,
				pageSize: this.pagingConfig.pageSize
			};
			this.showPage = showPage;
			this.pagingConfig.tableLoading = true;
			getEquipmentShiftList(data).then(response => {
				const { data, status } = response;
				this.pagingConfig.tableLoading = false;
				if (status === 200) {
					this.showPage = true;
					this.pagingConfig.total = data.totalCount;
					let result = [];
					if (data.data != null && data.data.length > 0) {
						result = data.data.map(v => {
							return Object.assign(v, {
								mainCategoryName: v.mainCategoryId == null ? null : this.filterCategoryName(v.mainCategoryId),
								middleCategoryName: v.middleCategoryId == null ? null : this.filterCategoryName(v.middleCategoryId),
								subcategoryName: v.subcategoryId == null ? null : this.filterCategoryName(v.subcategoryId)
							});
						});
					}
					console.info(result);
					this.pagingConfig.tableData = [...result];
				} else {
					this.notification().warn({
						body: data.errMsg
					});
				}
			});
		},
		initCategoryList() {
			getEquipmentCategoryList({
				page: 0,
				pageSize: 0
			}).then(res => {
				const { data, status } = res;
				if (status === 200) {
					this.categoryFullList = [...data.data];
				}
			});
		},
		filterCategoryName(id) {
			if (this.categoryFullList == null || this.categoryFullList.length <= 0 || id === '') {
				return null;
			} else {
				let targetItems = this.categoryFullList.filter(v => v.id === id);
				if (targetItems != null && targetItems.length > 0) {
					return targetItems[0].categoryName;
				} else {
					return null;
				}
			}
		},
		formatConfirmStatus(value) {
			if (value == null || value === '') {
				return this.$t('transfer.status.wait');
			} else {
				let key = value.toString().toUpperCase();
				if ('WAIT_TO_CONFIRM' === key) {
					return this.$t('transfer.status.wait');
				} else if ('CONFIRMED' === key || 'CONFIRM' === key) {
					return this.$t('transfer.status.confirmed');
				} else if ('REJECTED' === key || 'REJECT' === key) {
					return this.$t('transfer.status.rejected');
				}
			}
		}
	}
};
</script>
<style lang="scss" scoped>
.shift-list-container {
	.operation {
		width: 100%;
		height: 48px;

		.dsn-button {
			margin: 0 8px 0 0;
		}
	}
}
</style>
