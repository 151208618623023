<template>
  <div class="equipment-scrap-container">
    <h1>设备设施报废</h1>
  </div>
</template>
<script>
export default {
  name: 'equipmentScrap',
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {
  },
  methods: {},
}
</script>
<style lang="scss">

.equipment-scrap-container {

}

</style>
