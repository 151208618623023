<template>
  <div class="equipment-position-ref-container">
    <DsnPanel>
      <div slot="header" class="title clearfix">
        <el-form
            :inline="true"
            :model="queryParam"
            ref="testQueryForm"
            :rules="{}"
            @submit.native.prevent
        >
        </el-form>
      </div>
      <div class="operation">
        <dsn-button
            size="medium"
            type="success"
            icon="el-icon-folder-add"
            @click.native="handleAdd"
			v-if="isAddBtn == true"
        >{{ this.$t('commbtn.add') }}
        </dsn-button>
        <dsn-button
            size="medium"
            type="primary"
            icon="el-icon-edit"
            :disabled="selectionList.length !== 1"
            @click="handleEdit"
            style="margin-left:0"
			v-if="isEditBtn == true"
        >{{ this.$t('commbtn.edit') }}
        </dsn-button>
        <dsn-button
            size="medium"
            type="danger"
            icon="el-icon-delete"
            style="margin-left:0"
			v-if="isDelBtn == true"
            :disabled="selectionList.length <= 0"
            @click="handleDeleteCheck"
        >{{ this.$t('commbtn.delete') }}
        </dsn-button>
      </div>
      <dsn-table
          @row-click="selectCheckBox"
          @selection-change="handleSelectionChange"
          ref="multipleTable"
          :row-key="'id'"
		  :border="true"
          v-loading="this.pagingConfig.tableLoading"
          :data="listData"
          tooltip-effect="dark"
          style="width: 100%;" :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
      >
        <el-table-column type="selection" width="55" align="center"/>
        <el-table-column
            prop="storageName"
            :label="this.$t('storage.fields.storageName')"
            width="300" align="left">
        </el-table-column>

        <el-table-column
            prop="keeperName"
            :label="this.$t('storage.fields.keeper')" width="250">
          <template slot-scope="scope">
            {{ showStorageKeeper(scope.row.keeperId) }}
          </template>
        </el-table-column>
        <!--        <el-table-column-->
        <!--            prop="productGroupCode"-->
        <!--            :label="this.$t('storage.fields.productGroupCode')"-->
        <!--            width="250" align="center">-->
        <!--        </el-table-column>-->
        <el-table-column
            prop="productionGroupCode"
            :label="this.$t('storage.fields.productGroupCode')"
            width="200" align="center">
        </el-table-column>
        <el-table-column
            prop="productionAreaFlag"
            :label="this.$t('storage.fields.productionAreaFlag')"
            width="200" align="center">
          <template slot-scope="scope">
            <i class="el-icon-check" v-if="scope.row.productionAreaFlag===1"></i>
<!--            <i class="el-icon-close" v-else></i>-->
          </template>
        </el-table-column>
        <el-table-column
            prop="costCenterNo"
            :label="this.$t('storage.fields.costCenterNo')"
            width="350" align="center">
			<template scope="scope">
				{{ costCenterNoFormatter(scope.row.costCenterNo) }}
			</template>
        </el-table-column>
        
      </dsn-table>
    </DsnPanel>
    <el-dialog title="删除" :visible.sync="deleteDialog" :width="defaltDialogWidth" v-el-drag-dialog>
      <span>是否确认删除{{ selectionList.length }}条数据？</span>
      <span slot="footer" class="dialog-footer">
        <dsn-button @click="deleteDialog = false">取 消</dsn-button>
        <dsn-button type="primary" @click="handleDelete">确 定</dsn-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {mapMutations} from "vuex";
import DsnFooter from "@/views/layout/dsn-footer";
import {deleteOneStorage, getStorageList,CostCenter} from "@/api/efms/equipments/equipment.storage.api";
import arrayToTree from "array-to-tree";
import {getUserList} from "@/api/efms/users/users.api";

export default {
  name: 'equipmentPositionRefSettings',
  components: {DsnFooter},
  inject: ["defaltDialogWidth"],
  data() {
    return {
      listData: [],
      queryParam: {
        deptId: null,
        position: null
      },
      pagingConfig: {
        current: 0,
        pageSize: 0,
        count: 0,
        tableLoading: false,
        total: 0
      },
      deleteDialog: false,
      selectionList: [],
      userList: [],
	  
	  isAddBtn:false,
	  isEditBtn:false,
	  isDelBtn:false,
	  
	  costList:[],
	  
    }
  },
  computed: {},
  created() {
    this.initData();
	this.getCostCenter();
	// console.log('++++++++++++++++++++++++++++++++++++++++++++++++');
	// console.log(JSON.parse(localStorage.getItem("USER_PERMISSIONS")));
	var permissionsList = JSON.parse(localStorage.getItem("USER_PERMISSIONS"));
	

	if(permissionsList.indexOf("api:storages:add") != -1){
		this.isAddBtn = true;
	}
	if(permissionsList.indexOf("api:storages:edit") != -1){
		this.isEditBtn = true;
	}
	if(permissionsList.indexOf("api:storages:del") != -1){
		this.isDelBtn = true;
	}

	
  },
  activated() {
    this.initData();
  },
  methods: {
	  
	  getCostCenter(){
	  		  let params = '';
	  		  CostCenter(params).then(costRes => {
	  		  	console.log(costRes);
	  		  	if (costRes.data.resultCode == 0) {
	  				this.costList = costRes.data.data
	  		  	}
	  		  });
	  },
	  
	  costCenterNoFormatter(data){
		  // console.log('++++++++++++++++++++++++++++',data);
		  var description = '';
		  this.costList.forEach(obj =>{
			  if(obj.CostCenterName == data){
				 description =   obj.Description;
			  }
		  })
		  return description;
	  },
	  
	  
    ...mapMutations(["STORAGELIST"]),
    handleQuery(formNames) {
      this.$refs[formNames].validate(valid => {
        if (valid) {
          this.initData();
        } else {
          return false;
        }
      });
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
      this.handleQuery();
    },
    handlePageSizeChange(pageSize) {
      this.pagingConfig.pageSize = pageSize;
      this.pagingConfig.current = 1;
      this.initData();
    },
    handleCurrentChange(currentChange) {
      this.pagingConfig.current = currentChange;
      this.initData();
    },
    handleSelectionChange(val) {
      this.selectionList = val
      // this.selectionList.push(val)
    },
    selectCheckBox(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    handleEdit() {
      const tempArr = JSON.parse(JSON.stringify(this.selectionList));
      this.STORAGELIST(tempArr);
      localStorage.setItem("storageList", JSON.stringify(this.selectionList));
      this.$router.push({
        name: "equipmentStockRefSettingsEdit",
        query: {operateType: "edit"}
      });
    },
    handleAdd() {
      this.STORAGELIST([]);
      this.$router.push({
        name: "equipmentStockRefSettingsAdd",
        query: {operateType: "add"}
      });
    },
    handleDelete() {
      const {selectionList} = this;
      console.info(selectionList)
      if (selectionList.length === 1) {
        let that = this;
        deleteOneStorage(selectionList[0].id).then(res => {
          const {status, data} = res;
          if (status === 200) {
            that.getStorageListPagingData()
            that.$message.success("删除成功");
          } else {
            that.$message.error("删除失败");
          }
        }).catch(err => {
          this.$message.error("删除失败");
        })
      } else {
        this.$message.error("请选择一条要删除的记录")
      }
    },
    handleDeleteCheck() {
      this.messageBox()
          .then(() => {
            this.handleDelete();
          })
          .catch(() => {
            this.notification().warn({
              body: "已经取消删除"
            });
          });
    },
    initData() {
      // this.initFakeData();
      this.getUserDataList();
      this.getStorageListPagingData()
    },
    getUserDataList() {
      const data = {
        page: 0,
        pageSize: 0,
      };
      getUserList(data).then((response) => {
        const {data, status} = response;
        if (status === 200) {
          this.userList = [...data.data]
          // this.notification().success({
          //   body: '请求成功',
          //   duration: 1
          // });
        } else {
          this.notification().warn({
            body: data.errMsg
          });
        }
      })

    },
    getStorageListPagingData() {
      let params = {
        page: this.pagingConfig.current,
        pageSize: this.pagingConfig.pageSize
      }
      this.pagingConfig.tableLoading = true;
      getStorageList(params).then(res => {
        const {status, data} = res;
        if (status != null && status === 200 && data && data.data != null) {
          let resultList = data.data;
          if (resultList != null && resultList.length > 0) {
            this.pagingConfig.total = data.totalCount;
            let tempList = resultList.map((v) => {
              return {
                id: v.id,
                parentId: v.parentId === '' ? null : v.parentId,
                storageName: v.equipmentStorageName,
                keeperId: v.keeperId,
                keeperName: v.keeperName,
                productionGroupCode: v.productionGroupCode,
                productionAreaFlag: v.productionAreaFlag,
                costCenterNo: v.costCenterNo == null ? v.costCenterId : v.costCenterNo,
                activeStatus: 1,
              }
            })
            let nodeList = arrayToTree(tempList, {
              parentProperty: 'parentId',
              childrenProperty: 'children'
            })
            this.listData = [...nodeList]
          }
        }
        this.pagingConfig.tableLoading = false;
      }).catch(err => {
        this.$message.error(`${this.$t('commmsg.remoteApiError')}`)
      })
    },
    showStorageKeeper(keeperId) {
      if (keeperId == null || keeperId === '') {
        return ""
      } else {
        let ids = keeperId.split(",");
        let displayName = [];
        if (ids.length > 0) {
          ids.forEach((v) => {
            let user = this.userList.filter(s => s.id === v);
            if (user != null && user.length === 1) {
              displayName.push(user[0].realname);
            }
          })
        }
        return displayName.join(",")
      }
    }
  }
}
</script>
<style lang="scss" scoped>

.equipment-position-ref-container {
  .operation {
    width: 100%;
    height: 48px;

    .dsn-button {
      margin: 0 8px 0 0;
    }
  }
}

//::v-deep.el-table .cell {
//  font-size: 18px;
//}

.el-tree ::v-deep {
  .el-tree-node__content {
    position: relative;
  }

  .el-tree-node:last-child > .el-tree-node__content::before {
    content: "";
    width: 1px;
    height: 10000px;
    background-color: #000;
    position: absolute;
    margin-left: -9px;
    bottom: 13px;
  }

  .el-tree-node__children .el-tree-node__content::after {
    content: "";
    width: 9px;
    height: 1px;
    background-color: #000;
    position: absolute;
    margin-left: -9px;
  }

  .el-tree-node__content > .el-tree-node__expand-icon {
    padding: 6px 3px;
  }
}

//::v-deep .el-table .el-table__expend-icon {
//  .el-icon-arrow-right:before {
//    content: "\e791";
//  }
//}


::v-deep .el-icon-arrow-right:before {
  content: "\e791";
}
</style>
