import request from "@/service/";


export const applyEquipmentShift = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/shifts/apply`,
        data
    );
}

export const confirmEquipmentTransfer = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/shifts/${data.id}/confirm`,
        data
    );
}

export const confirmTargetEquipmentTransfer = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/shifts/${data.id}/targetConfirm`,
        data
    );
}

export const rejectEquipmentShift = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/shifts/${data.id}/reject`,
        data
    );
}

export const rejectTargetEquipmentShift = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/shifts/${data.id}/targetReject`,
        data
    );
}


export const updateEquipmentShiftById = (data) => {
    return request.put(
        `${window.VUE_APP_URL}/api/equipments/shifts/${data.id}`,
        data
    );
}


export const getEquipmentShiftList = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/equipments/shifts`,
        {
            params: params
        }
    );
}

export const EndShifts = (params) => {
    return request.get(
        `${window.VUE_APP_URL}/api/EquipmentMaintenance/EndShifts`,{
            params: params
        }
    );
}

export const FinishOrder = (data) => {
    return request.post(
        `${window.VUE_APP_URL}/api/equipments/FinishOrder`,
        data
    );
}